<template>
    <div class="layout">
        <div class="layout-top">
            <img class="logo" :src="require('@/assets/Image/logo.png')" alt="">
            <div class="title">—  企业云服务平台</div>
            <div class="userContent">
                <div class="userName"><ellipsis width="85" num="1" :content="user.member.member.nickname" textStyle="display:block"></ellipsis></div>
                <com-info placement="bottomRight" v-if="user.member">
                    <template #avatar="{ visible }">
                        <div class="company">
                            <div class="companyName">{{user.member.enterprise.enterpriseName}}</div>
                            <UpOutlined v-if="visible" class="comIcon" />
                            <DownOutlined v-else class="comIcon" />
                            <div class="clear"></div>
                        </div>
                    </template>
                </com-info>
            </div>
            <user-info placement="bottomRight" v-if="user.member">
                <template #avatar>
                    <a-avatar  size="large" :src="user.member.member.headPortrait==null?'':user.member.member.headPortrait" class="avatar">
                        {{user.member.member.headPortrait==null?user.realName.charAt(0):''}}
                    </a-avatar>
                </template>
            </user-info>
            <a-badge class="inform" :count="user.info.length" @click="tolink('/message')">
                <svg-icon icon-class="info" />
            </a-badge>
            <img class="serve" :src="require('@/assets/Image/serve.png')" @click="tolink('/openServer')" alt="">
            <img class="tips" :src="require('@/assets/Image/tips.png')" @click="tolink('/help')" alt="">
        </div>
        <div v-if="menuIsShow" class="layout-main">
            <div class="layout-menuAll">
                <div v-for="(item,index) in menu" :key="index" 
                    :class="selectMenu.all==item.id?'item active':'item'" @click="switchMenu(item)">
                    <!-- <div class="selected"></div> -->
                    <img :class="item.icon" :src="require(`@/assets/Image/menu/${item.icon}.png`)" alt="">
                    {{item.name}}
                </div>
            </div>
            <div class="layout-menu" v-if="chilrenMenu">
                <div v-for="(item,index) in chilrenMenu" :key="index">
                    <div class="item"
                    :class="selectMenu.menu==item.url?'item active':'item'" @click="menuItemClick(item)">
                        <!-- <img class="project" :src="require('@/assets/Image/menu/project.png')" alt=""> -->
                        <img :class="item.icon" :src="require(`@/assets/Image/menu/${item.icon}.png`)" alt="">
                        {{item.name}}
                    </div>
                    <div class="bor" v-if="item.border"></div>
                    <div class="clear" v-if="item.border"></div>
                </div>
            </div>
                <div class="layout-view" :style="chilrenMenu!=null?'width: calc(100% - 280px);':'width: calc(100% - 90px);'">
                    <a-spin style="height: calc(100vh - 90px);max-height: calc(100vh - 90px)" :spinning="allLoading">
                        <router-view/>
                    </a-spin>
                </div>
            <div class="clear"></div>
        </div>
        <newUser v-model:visible="newUserVisible" :allNum="numData.total??0" :curNum="numData.currCount??0"></newUser>
    </div>
</template>
<script>
import userInfo from '@/components/userInfo'
import comInfo from '@/components/userInfo/comIndex.vue'
import newUser from '@/components/newUser'
import {findManyArray} from '@/utils/common'
import { mapGetters, mapState } from "vuex";
import {list} from '../mixins/list'
import { getCount } from '@/api/user'
export default {
    props:{
        menuIsShow:{
            type:Boolean,
            default:true
        }
    },
    mixins:[list],
    components: {
        userInfo,newUser,comInfo
    },
    data(){
        return{
            selectMenu:{
                all:0,
                menu:''
            },
            numData:{},
            newUserVisible:false
        }
    },
    async created(){
        this.watchSelectMenu()
        console.log(localStorage.getItem('isNewLogin')==true)
        if(localStorage.getItem('isNewLogin')=='true'){
            let num = await getCount({code:'AuditMember'})
            this.numData = num.data
            console.log(this.numData)
            if(this.numData.currCount<1000){
                this.newUserVisible=true
            }
            localStorage.setItem('isNewLogin',false)
        }
    },
    methods:{
        switchMenu(item){
            this.selectMenu.all=item.id
            if(item.url){
                this.tolink(item.url)
                return;
            }
            if(this.chilrenMenu[0]){
                this.selectMenu.menu=this.chilrenMenu[0].url
                this.tolink(this.selectMenu.menu)
            }else{
                this.selectMenu.menu=''
            }
        },
        watchSelectMenu(){
            // console.log(this.activeMenu)
            if(this.activeMenu){
                if(this.activeMenu.parentId){
                    this.selectMenu.all=this.activeMenu.parentId
                    this.selectMenu.menu=this.activeMenu.url
                }else{
                    this.selectMenu.all=this.activeMenu.id
                    this.selectMenu.menu=''
                }
            }else{
                this.selectMenu={
                    all:0,
                    menu:''
                }
            }
        },
        menuItemClick(item){
            this.selectMenu.menu=item.url
            this.tolink(item.url)
        }
    },
    computed: {
        ...mapGetters(["menu","user",'allLoading']),
        activeMenu() {
            const route = this.$route;
            let item = findManyArray(this.menu, 'chilren', 'url', route.fullPath)
            return item;
        },
        chilrenMenu() {
            let obj = this.menu.find(item => item.id == this.selectMenu.all)
            if(obj){
                return obj.chilren.length==0?null:obj.chilren
            }else{
                return null
            }
        }
    },
    watch:{
        $route(val){
            this.watchSelectMenu()
        }
    }
}
</script>
<style lang="less" scoped>
@import '@/assets/css/layoutIcon.less';
.layout{
    background-color: #ffffff;
    &-top{
        height: 55px;
        border-bottom: 1px solid #DEDFE0;
        text-align: left;
        .logo{
            // width: 129px;
            height: 28px;
            margin-top: 16px;
            margin-left: 56px;
            float: left;
            cursor: pointer;
        }
        .title{
            // font-size: 20px;
            font-weight: 700;
            color: #d71b24;
            margin-left: 12px;
            line-height: 55px;
            float: left;
        }
        .avatar{
            background-color: #f56a00;
            vertical-align: middle;
            float: right;
            margin-top: 8px;
            margin-right: 12px;
            cursor: pointer;
        }
        .userContent{
            float: right;
            margin-right: 50px;
            color: #333333;
            .userName{
                margin-top: 3px;
            }
            .company{
                width: 104px;
                cursor: pointer;
                &:hover{
                    color: #2d9fff;
                }
                .companyName{
                    float: left;
                    width: 85px;
                    overflow:hidden;
                    text-overflow: ellipsis;
                    white-space:nowrap;
                }
                .comIcon{
                    float: right;
                    margin-top: 4px;
                    margin-left: 3px;
                }
            }
        }
        .inform{
            float: right;
            margin-top: 18px;
            font-size: 20px;
            cursor: pointer;
            margin-right: 80px;
            // img{
            //     width: 28px;
            //     height: 28px;
            //     cursor: pointer;
            // }
            &:hover{
                color: #2d9fff;
            }
        }
    }
    &-main{
        height: calc(100vh - 55px);
        .layout-menuAll{
            width: 90px;
            height: 100%;
            float: left;
            border-right: 1px solid #DEDFE0;
            .item{
                padding-top: 11px;
                padding-bottom: 21px;
                border-radius: 5px;
                text-align: center;
                color: #999999;
                margin-bottom: 18px;
                margin: 0 5px;
                margin-top: 5px;
                cursor: pointer;
                &:hover{
                    color: #128BF1;
                }
                img{
                    padding-top: 10px;
                    width: 22px;
                    height: 32px;
                    display: block;
                    margin: 0 auto;
                }
            }
            .selected{
                width: 4px;
                height: 50px;
                background-color: #ffffff;
                float: left;
            }
            .active{
                background-color: #e6f4ff;
                color: #2d9fff;
                // .selected{
                //     background-color: #128bf1;
                // }
            }
        }
        .layout-menu{
            width: 190px;
            height: 100%;
            overflow: auto;
            float: left;
            border-right: 1px solid #DEDFE0;
            padding-top: 15px;
            .item{
                height: 60px;
                line-height: 60px;
                border-radius: 10px;
                color: #333333;
                margin: 0 15px;
                text-align: left;
                cursor: pointer;
                img{
                    width: 22px;
                    height: 22px;
                    float: left;
                    margin: 19px;
                    margin-bottom: 0px;
                }
                &:hover{
                    color: #2d9fff;
                }
            }
            .bor{
                float: right;
                width: 268px;
                height: 1px;
                background: #dedfe0;
                margin: 10px 0px;
            }
            .active{
                background-color: #2d9fff;
                color: #ffffff;
                &:hover{
                    color: #ffffff;
                }
            }
        }
        .layout-view{
            width: calc(100% - 270px);
            height: 100%;
            float: left;
            overflow: auto;
        }
    }
}
/deep/ .ant-badge-count{
    right: 3px;
}
/deep/ .ant-spin-nested-loading{
    height: 100%;
}
/deep/.ant-spin-container{
    height: 100%;
}
.tips{
    float: right;
    margin-top: 18px;
    margin-right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover{
        content: url("@/assets/Image/tipsBlue.png");
    }
}
.serve{
    float: right;
    margin-top: 18px;
    margin-right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover{
        content: url("@/assets/Image/serveBlue.png");
    }
}
.clear{
    clear: both;
}
</style>