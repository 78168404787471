<template>
    <a-modal title="切换企业" width="500px" v-model:visible="dialogVisible" centered :footer="null"
    :closable="user.member.employees==null||user.member.employees.status!=-1" :keyboard="user.member.employees==null||user.member.employees.status!=-1" :maskClosable="user.member.employees==null||user.member.employees.status!=-1">
        <a-spin :spinning="loading">
            <a-button type="primary" style="margin-left:10px;margin-bottom:15px" @click="openAddBus">添加企业</a-button>
            <div class="busList">
                <div :class="activeBus==item.eno?'busItem active':'busItem'" v-for="(item,index) in enterList" :key="index">
                    <div class="selected">
                        <check-outlined />
                    </div>
                    <div class="content">
                        <div class="name">{{item.enterprise.enterpriseName}}</div>
                        <div class="date">上次进入时间:{{item.lastVisitTime}}</div>
                    </div>
                    <div class="busRight">
                        <a-button v-if="item.status==1" type="primary" @click="changeEnter(item,index)">进入</a-button>
                        <div v-else>
                            <span class="bj" v-if="item.status==-1" style="margin-right: 30px;">已禁用</span>
                            <span class="sq" v-if="item.status==2">申请中</span>
                            <span class="bj" v-if="item.status==4" style="margin-right: 30px;">被拒绝</span>
                            <span class="esc" @click="applyCancel(item)" v-if="item.status!=-1" style="margin-right: 30px;">>取消申请</span>
                        </div>
                    </div>
                </div>
            </div>
        </a-spin>
    <addBusiness v-model:visible="addBusVisible"></addBusiness>
    </a-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {list} from '@/mixins/list'
import { getUserEntList,applyCancelEnterprise } from '@/api/enterprise'
import { changeUserEnterprise } from '@/api/user'
import addBusiness from './addBusiness'
export default {
    mixins:[list],
    components: {
        addBusiness
    },
    props:{
        visible:{
            type:Boolean,
            default:false
        }
    },
    model: {
        prop: 'visible'
    },
    data() {
        return {
            dialogVisible: this.visible,
            value1:'',
            addBusVisible:false,
            activeBus:'',
            enterList:[]
        }
    },
    watch: {
        dialogVisible(value) {
            // console.log('子组件下拉框值发生改变：', this.dialogVisible)
            if(value){
                this.dialgoInit()
            }
            this.$emit('update:visible', this.dialogVisible)
        },
        visible(value){
            this.dialogVisible = value
        },
        addBusVisible(value){
            if(!value){
                this.dialgoInit()
            }
        }
    },
    created(){
        this.dialgoInit()
    },
    methods:{
        // 获取可切换信息
        dialgoInit(){
            this.loading = true
            if(this.user.member){
                this.activeBus = this.user.member.eno
            }
            getUserEntList({userNo:this.user.userlName}).then(res =>{
                this.enterList = res.data
                console.log(this.enterList)
                this.loading = false
            })
        },
        // 切换企业
        changeEnter(item, index){
            changeUserEnterprise({eNo:item.eno}).then(res =>{
                this.$message.success('已切换')
                this.activeBus = item.eno
                this.$router.go(0)
            })
        },
        //取消申请
        applyCancel(item){
            applyCancelEnterprise(item.id).then(res=>{
                this.$message.success('取消成功')
                this.dialgoInit()
            })
        },
        // 打开添加企业弹窗
        openAddBus(){
            this.addBusVisible=true
        },
    },
    computed:{
        ...mapGetters(["user"]),
    }
}
</script>
<style lang="less" scoped>
.busList{
    height: 400px;
    overflow: auto;
    .busItem{
        height: 76px;
        border-radius: 6px;
        border: 1px solid #dedfe0;
        margin: 0px 10px;
        margin-bottom: 22px;
        padding: 18px 16px;
        .selected{
            width: 18px;
            height: 18px;
            background: #4977fc;
            line-height: 18px;
            text-align: center;
            border-radius: 50%;
            color: #ffffff;
            font-size: 10px;
            float: left;
            margin-top: 10px;
            visibility: hidden;
        }
        .content{
            float: left;
            margin-left: 16px;
            .name{
                color: #333333;
                line-height: 14px;
                margin-bottom: 10px;
            }
            .date{
                color: #333333;
                font-size: 12px;
                line-height: 12px;
                color: #999999;
            }
        }
        .busRight{
            float: right;
            button{
                margin-right: 30px;
                margin-top: 3px;
            }
            .sq{
                display: inline-block;
                margin-top: 10px;
                cursor: pointer;
                color: #666666;
                &:hover{
                    color: #404040;
                }
            }
            .bj{
                display: inline-block;
                margin-top: 10px;
                cursor: pointer;
                color: #999999;
                &:hover{
                    color: #6d6d6d;
                }
            }
            .esc{
                display: inline-block;
                margin-top: 10px;
                color: #4977fc;
                cursor: pointer;
                margin-left: 33px;
                &:hover{
                    color: #2749ae;
                }
            }
        }
    }
    .active{
        background: #f1f4ff;
        border: 1px solid #4977fc;
        .selected{
            visibility: initial;
        }
        .busRight{
            display: none;
        }
    }
}
</style>