<template>
    <a-modal title="优惠卷" width="610px" v-model:visible="dialogVisible" centered :footer="null">
        <a-spin :spinning="loading">
            <div class="couNav">已领取优惠券</div>
            <div class="couList" v-if="couponList.length>0">
                <div class="couList-item" v-for="(item, index) in couponList" :key="index">
                    <div class="left" :style="item.canUse==1&&item.expire==0?'':'background: #a5abb2;'">
                        <span class="fmoney">￥</span>
                        <span class="nowMoney">{{item.couponsMoney}}</span>
                    </div>
                    <div class="main">
                        <div class="top">{{item.title}}</div>
                        <div class="bottom">* {{item.notes}}</div>
                    </div>
                    <div class="right">
                        <div class="tip">
                            <a-checkbox v-if="item.canUse==1&&item.expire==0" v-model:checked="item.checked"></a-checkbox>
                            <span style="color: #999999;" v-if="item.canUse==0||item.expire==1">{{item.expire==1?'已过期':item.canUse==0?'不可用':''}}</span>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <a-empty style="margin-top:80px;margin-bottom:30px" v-else />
            <div class="couNav" style="margin-top:15px">可领取优惠券</div>
            <div class="couList" style="height:151px" v-if="unCouponList.length>0">
                <a-spin :spinning="receiveLoading">
                    <div class="couList-item" v-for="(item, index) in unCouponList" :key="index">
                        <div class="left">
                            <span class="fmoney">￥</span>
                            <span class="nowMoney">{{item.couponsMoney}}</span>
                        </div>
                        <div class="main">
                            <div class="top">{{item.title}}</div>
                            <div class="bottom">* {{item.notes}}</div>
                        </div>
                        <div class="right">
                            <div class="btn" @click="receive(item)">
                                立即领取
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </a-spin>
            </div>
            <a-empty style="margin-top:80px;margin-bottom:30px" v-else />
            <div class="moenyBottom">
                <span>已优惠￥{{allMoney}}</span>
                <a-button type="primary" class="pribtn" @click="goSave">确定</a-button>
            </div>
        </a-spin>
        <!-- <div class="couNav">已使用优惠券</div>
        <div class="couList" style="height:151px">
            <div class="couList-item" v-for="(item, index) in 1" :key="index">
                <div class="left" style="background: #a5abb2;">
                    <span class="fmoney">￥</span>
                    <span class="nowMoney">190</span>
                </div>
                <div class="main">
                    <div class="top">满500可用</div>
                    <div class="bottom">* 仅可用于审计服务中财务账功能购买中</div>
                </div>
                <div class="right">
                    <div class="tip" style="color: #999999;">
                        已使用
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div> -->
    </a-modal>
</template>
<script>
import { getCouponList,receiveCoupon,getUnCouponList } from '@/api/coupon'
export default {
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        value:{
            type:Array,
            default:()=>[]
        },
        searchParam:{
            type:Object,
            default:()=>{}
        }
    },
    model: {
      prop: 'visible'
    },
    data() {
        return {
            dialogVisible: this.visible,
            checked:false,
            couponList:[],
            unCouponList:[],
            loading:false,
            receiveLoading: false
        }
    },
    methods:{
        diaInit(){
            this.loading = true
            getCouponList({...this.searchParam}).then(res => {
                // console.log(res.data)
                this.couponList = res.data.map(item => {item.checked = false; return item})
                for(let obj of this.value){
                    let index = this.couponList.findIndex(o => o.id == obj.id)
                    this.couponList[index].checked = true
                }
                this.loading = false
            })
            this.receiveLoading = true
            getUnCouponList({}).then(res => {
                this.unCouponList = res.data
                this.receiveLoading = false
            })
        },
        goSave(){
            this.$emit('submitSelectCoupon',this.couponList.filter(item => item.checked))
            this.dialogVisible = false
        },
        receive(item){
            console.log(item)
            receiveCoupon({couponId:item.id}).then(res => {
                this.diaInit()
                this.$message.success('领取成功!')
            })
        }
    },
    watch: {
      dialogVisible(value) {
        // console.log('子组件下拉框值发生改变：', this.dialogVisible)
        this.$emit('update:visible', this.dialogVisible)
        if(this.dialogVisible){
            this.diaInit()
        }
      },
      visible(value){
        this.dialogVisible = value
      }
    },
    computed:{
        //当前选中的会员是否已开通且并未到期
        allMoney(){
            let money = 0
            let list = this.couponList.filter(item => item.checked)
            for(let item of list){
                money += item.couponsMoney
            }
            return money
        },
    }
}
</script>
<style lang="less" scoped>
.couNav{
    color: #333333;
    margin-bottom: 15px;
}
.couList{
    height: 263px;
    overflow: auto;
    &-item{
        height: 131px;
        margin: 10px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(62,63,65,0.08);
        padding: 15px;
        cursor: pointer;
        &:hover{
            box-shadow: 0px 0px 8px 0px rgba(62, 63, 65, 0.15)
        }
        .left{
            width: 145px;
            float: left;
            height: 100%;
            background: #4977fc;
            border-radius: 8px;
            color: #ffffff;
            text-align: center;
            padding-top: 15px;
            .fmoney{
                font-size: 20px
            }
            .nowMoney{
                font-size: 50px;
                line-height: 66px;
            }
        }
        .main{
            margin-left: 20px;
            margin-right: 15px;
            float: left;
            margin-top: 23px;
            .top{
                font-size: 18px;
                color: #333333;
            }
            .bottom{
                font-size: 12px;
                color: #999999;
                margin-top: 6px;
            }
        }
        .right{
            float: right;
            .btn{
                margin-top: 32px;
                width: 97px;
                height: 36px;
                line-height: 36px;
                background: #ffffff;
                border: 1px solid #4977fc;
                border-radius: 19px;
                cursor: pointer;
                font-size: 16px;
                text-align: center;
                color: #4977fc;
                &:hover{
                    background: #4977fc;
                    color: #ffffff;
                }
            }
            .tip{
                font-size: 16px;
                color: #4977fc;
                line-height: 101px;
                width: 97px;
                text-align: center;
                &:hover{
                    color: #011a63;
                }
            }
        }
    }
}
::v-deep .ant-checkbox-inner{
    border-radius: 50%;
    width: 28px;
    height: 28px;
}
::v-deep .ant-checkbox-inner::after{
    left: 24.5%;
    top: 49%;
    width: 9.5px;
    height: 15px;
}
::v-deep .ant-checkbox-checked::after{
    border: none;
}
.moenyBottom{
    margin-top: 30px;
    text-align: right;
    span{
        font-size: 17px;
        font-weight: 700;
        margin-right: 10px;
    }
}
.clear{
    clear: both;
}
</style>