<template>
    <div class="perMain">
         <a-avatar-group :max-count="3" :max-style="{ color: '#f56a00', backgroundColor: '#fde3cf', marginLeft: '0px',lineHeight: '28px', cursor: 'pointer' }">
            <div class="avatar" v-for="(item,index) in allList" :key="index">
                <div class="avatarrel">
                    <a-avatar style="line-height: 28px;" :src="item.url==null?'':item.url">
                        {{item.url==null||item.url==''?item.name.charAt(0):''}}
                    </a-avatar>
                    <close-circle-filled class="remove" @click="removeUser(item)"/>
                </div>
                <div class="name">{{item.name}}</div>
            </div>
        </a-avatar-group>
        <a-avatar class="addUser" @click="userVisible=true" :src="require('../../../assets/Image/popover/addUser.png')"></a-avatar>
        <selectUser v-model:visible="userVisible" :value="selectValue" mode="multiple" @submitSelect="submitSelect"></selectUser>
    </div>
</template>
<script>
import selectUser from '@/components/selectUser'
export default {
    components:{
        selectUser
    },
    props:{
        value:{
            type:Array,
            default:()=>[]
        }
    },
    model: {
      prop: 'value'
    },
    data() {
        return {
            allList: this.value,
            selectValue: this.value.map(item => {
                return item.userNO
            }),
            userVisible:false,
        }
    },
    methods:{
        //选中人员后事件
        submitSelect(value){
            this.allList = value.map(item => {
                return {
                    // userid:item.userid,
                    userNO:item.userNO,
                    name:item.name==''?'企业用户':item.name,
                    url:item.headPortrait
                }
            })
            this.userVisible = false
            // console.log(value)
            this.selectValue = value.map(item => {
                return item.userNO
            })
            console.log(this.selectValue)
        },
        //删除选中人员
        removeUser(item){
            this.allList.splice(this.allList.findIndex(a => a.userNO == item.userNO), 1)
            this.selectValue.splice(this.selectValue.findIndex(val => val == item.userNO), 1)
        }
    },
    watch: {
      allList(value) {
        // console.log('子组件下拉框值发生改变：', this.dialogVisible)
        // console.log(value)
        // console.log(this.allList)
        this.$emit('update:value', this.allList)
      },
      value(value){
        this.allList = value
      }
    }
}
</script>
<style lang="less">
.avatarrel{
    position: relative;
    display: inline-block;
    .remove{
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: #ffffff;
        border-radius: 6px;
        color: #fa4f47;
        cursor: pointer;
        font-size: 12px;
        display: none;
    }
    &:hover .remove{
        display: block;
    }
}
.addUser{
    margin-left: 8px !important;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 8px 0.9px rgba(216, 216, 216, 0.6); 
    }
}
.avatar{
    display: inline-block;
    text-align: center;
    margin-right: 8px;
}
.name{
    font-size: 12px;
    color: #7b7b7b;
    margin-top: 3px;
    margin-bottom: 10px;
}
/deep/ .ant-avatar-group .ant-avatar:not(:first-child){
    margin-left: 8px;
}
// /deep/ .ant-avatar-string{
//     line-height: 28px !important;
// }
</style>