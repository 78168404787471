<template>
    <a-modal title="优惠明细" width="578px" v-model:visible="dialogVisible" centered :footer="null">
        <a-row>
            <a-col class="tableItem" :span="5">模块</a-col>
            <a-col class="tableItem" :span="4">价格</a-col>
            <a-col class="tableItem" :span="1"></a-col>
            <a-col class="tableItem" :span="4">优惠</a-col>
            <a-col class="tableItem" :span="1"></a-col>
            <a-col class="tableItem" :span="4">卷</a-col>
            <a-col class="tableItem" :span="1"></a-col>
            <a-col class="tableItem" :span="4">合计</a-col>
        </a-row>
        <div class="main">
            <a-row v-for="(item, index) in 3" :key="index">
                <a-col class="tableItem" :span="5">审计会员</a-col>
                <a-col class="tableItem" :span="4">10000</a-col>
                <a-col class="tableItem fh" :span="1">-</a-col>
                <a-col class="tableItem" :span="4">5000</a-col>
                <a-col class="tableItem fh" :span="1">-</a-col>
                <a-col class="tableItem" :span="4">5000</a-col>
                <a-col class="tableItem" style="font-size:18px" :span="1">=</a-col>
                <a-col class="tableItem" :span="4">0</a-col>
            </a-row>
        </div>
        <div class="total">×3</div>
        <div class="totalMoney">
            合计：
            <span class="fmoney">￥</span>
            <span class="nowMoney">1999</span>
        </div>
    </a-modal>
</template>
<script>
export default {
    props:{
        visible:{
            type:Boolean,
            default:false
        }
    },
    model: {
      prop: 'visible'
    },
    data() {
        return {
            dialogVisible: this.visible,
        }
    },
    watch: {
      dialogVisible(value) {
        // console.log('子组件下拉框值发生改变：', this.dialogVisible)
        this.$emit('update:visible', this.dialogVisible)
      },
      visible(value){
        this.dialogVisible = value
      }
    }
}
</script>
<style lang="less" scoped>
.tableItem{
    text-align: center;
    margin-bottom: 15px;
    line-height: 23px;
    color: #999999;
}
.main{
    background: #f4f5f6;
    padding-top: 15px;
    .tableItem{
        color: #333333;
    }
}
.fh{
    font-size: 27px;
    line-height: 19px;
}
.total{
    color: #333333;
    margin-top: 20px;
    text-align: right;
}
.totalMoney{
    // float: right;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 5px;
    .fmoney{
        font-size: 14px;
        color: #ff1e1e;
    }
    .nowMoney{
        font-size: 30px;
        font-weight: 700;
        line-height: 23px;
        color: #ff1e1e;
    }
}
.clear{
    clear: both;
}
</style>