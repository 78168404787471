import request from '@/utils/request'

const api = '/pro/enterpriseOrganizational/'
const userApi = '/pro/enterpriseEmployees/'
const posApi = '/pro/enterprisePosition/'
const enterApi = '/webapi/userEnterprise/'

// 企业-组织架构列表
export function getDepartment(data) {
    return request({
      url: api+'list',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}
// 企业-员工列表
export function getDepartmentUser(data) {
    return request({
      url: userApi+'list',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      params:data
    })
}
// 修改企业-员工
export function updateDepartmentUser(data) {
    return request({
      url: userApi+'update',
      method: 'put',
      data:data
    })
}
// 添加企业-组织架构
export function addDepart(data) {
  return request({
    url: api+'save',
    method: 'post',
    data:data
  })
}
// 修改企业-组织架构
export function editDepart(data) {
  return request({
    url: api+'update',
    method: 'put',
    data:data
  })
}
// 修改企业-组织架构
export function deleteDepart(data) {
  return request({
    url: api+`delete/${data.id}`,
    method: 'delete',
    params:data
  })
}

// 企业-岗位列表
export function getPosition(data) {
    return request({
      url: posApi+'list',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}
// 当前企业申请人列表
export function getApplyUser(data) {
    return request({
      url: enterApi+'applyList',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      params:data
    })
}
// 员工状态修改
export function changeUserStatus(data) {
  return request({
    url: userApi+'updateStatus',
    method: 'put',
    data:data
  })
}
// 员工申请状态修改
export function userApplyStatus(data) {
  return request({
    url: enterApi+'updateStatus',
    method: 'put',
    params:data
  })
}
// 员工状态批量修改
export function changeUserStatusBatch(data) {
  return request({
    url: userApi+'batchUpdateStatus',
    method: 'put',
    params:data
  })
}
// 员工批量调整部门
export function changeUserDepartmentBatch(data) {
  return request({
    url: userApi+'changeDepartment',
    method: 'put',
    params:data
  })
}
// 企业-组织架构列表
export function getOrgManager(data) {
    return request({
      url: api+'getOrgManager',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}

// 企业-组织架构列表
export function getByUserNo(data) {
  return request({
    url: '/pro/enterpriseEmployees/getByUserNo',
    method: 'get',
    params:data
  })
}
