<template>
    <a-modal :title="null" :bodyStyle="{background:`url(${require('@/assets/Image/model/modelBackImg.png')})`,backgroundSize: 'cover',height: '703px'}" width="500px" v-model:visible="dialogVisible" centered :footer="null">
        <template #closeIcon>
            <CloseOutlined style="color:#ffffff" />
        </template>
        <div class="topHeader">
            <span style="font-size:33px">会员专享-</span>
            推荐好友享壕礼
        </div>
        <img class="redBag" :src="require('@/assets/Image/model/redBag.png')" alt="">
        <div class="redContent">
            <a-row>
                <a-col :span="11">
                    <div class="num">
                        2900
                        <div class="dw">人</div>
                        <div class="clear"></div>
                    </div>
                    <div class="numContent">累计推荐</div>
                </a-col>
                <a-col :span="2" style="text-align:center">
                    <div class="borderDiv"></div>
                </a-col>
                <a-col :span="11">
                    <div class="num" style="text-align: left;">
                        <div style="float:left;margin-left:21px">
                            87000
                        </div>
                        <div class="dw" style="float:left;">
                            <div style="line-height: 18px;">积</div>
                            <div style="line-height: 18px;">分</div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="numContent" style="text-align: left;margin-left:45px">累计收益</div>
                </a-col>
            </a-row>
            <a-row style="margin-top:12px">
                <a-col :span="11" class="textRight">
                    <img class="qrInvite" :src="require('@/assets/Image/wx.png')" alt="">
                </a-col>
                <a-col :span="2" style="text-align:center">
                    <div class="borderDiv" style="margin-top:45px"></div>
                </a-col>
                <a-col :span="11" style="padding-left: 21px;">
                    <div class="inviteContent">复制专属链接分享给好友</div>
                    <div class="urlInput">https://app.mockplus.cn/app/vSA4H5Ew4/develop/design/L2mFjNkpS</div>
                    <div class="inviteBtn">点击复制</div>
                </a-col>
            </a-row>
            <div class="header">活动规则</div>
            <div class="pRowContent">1、活动时间: 2023年5月10日 -2023年8月10日</div>
            <div class="pRowContent">2、您可通过扫描二维码进入邀请好友页面，点击页面中邀请好友按钮分享给好友，或者复制右侧专属链接分享给好友。成功邀请好友成为会员，您可获得300元积分（1积分＝1元，积分可用于平台消费）</div>
            <div class="pRowContent">3、活动最终解释权归北京启典财智科技有限公司所有</div>
        </div>
    </a-modal>
</template>
<script>
import { list } from '@/mixins/list'
import { dialog } from '@/mixins/dialog'
// import { upload } from '@/api/file'
export default {
    mixins:[list,dialog],
    data(){
        return{
        }
    },
    methods:{
        diaInit(){
        },
    }
}
</script>
<style lang="less" scoped>
.btns{
    margin-top: 38px;
    text-align: center;
}
.topHeader{
    font-size: 28px;
    text-align: center;
    text-shadow: 0px 4px 7px 0px rgba(212,4,29,0.93);
    color: #FEE5DD;
    margin-bottom: 3px;
}
.redBag{
    width: 452px;
    z-index: 98;
    position: relative;
}
.redContent{
    width: 452px;
    height: 358px;
    background-color: #ffffff;
    margin-top: -140px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: relative;
    z-index: 99;
    padding: 5px;
}
.num{
    font-size: 22px;
    color: #FF2A38;
    // margin-bottom: 3px;
    margin-top: 23px;
    text-align: right;
    margin-right: 21px;
    line-height: 35px;
    font-weight: 700;
    .dw{
        font-size: 12px;
        font-weight: 400;
        width: 12px;
        float: right;
        line-height: 36px;
        margin-left: 5px;
    }
}
.numContent{
    color: #918b87;
    font-size: 12px;
    text-align: right;
    margin-right: 50px;
}
.borderDiv{
    width: 1px;
    height: 38px;
    background-color: #e6e6e6;
    display: inline-block;
    margin-top: 33px;
}
.qrInvite{
    width: 130px;
    height: 130px;
    margin-right: 21px;
}
.textCenter{
    text-align: center;
}
.textRight{
    text-align: right;
}
.textLeft{
    text-align: left;
}
.inviteContent{
    font-size: 12px;
    color: #918b87;
    margin-top: 11px;
}
.urlInput{
    height: 40px;
    line-height: 40px;
    background-color: #f8f9fa;
    margin-top: 12px;
    width: 165px;
    padding: 0 10px;
    font-size: 12px;
    color: #999999;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.inviteBtn{
    width: 103px;
    height: 34px;
    background: linear-gradient(0deg,#ff001d 0%, #ff5d5b 100%);
    border-radius: 25px;
    box-shadow: 0.07px 4px 17px 0px rgba(239,49,0,0.40);
    cursor: pointer;
    text-align: center;
    color: #ffffff;
    line-height: 34px;
    margin-top: 12px;
}
.header{
    font-size: 12px;
    color: #918B87;
    margin-top: 13px;
    margin-left: 25px;
}
.pRowContent{
    padding: 0 25px;
    font-size: 10px;
    color: #918B87;
}
.clear{
    clear: both;
}
</style>