import request from '@/utils/request'

const api = '/webapi/userCoupons/'

// 获取可使用的优惠卷
export function getByUserCoupon(data) {
    return request({
      url: api+'getByUser',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}
// 获取可使用的优惠卷
export function getCouponList(data) {
    return request({
      url: api+'getList',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}

// 获取可使用的优惠卷
export function receiveCoupon(data) {
    return request({
      url: api+'createUserCoupons',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}
// 获取可使用的优惠卷
export function getUnCouponList(data) {
    return request({
      url: api+'unclaimed',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}
// 获取已使用的优惠卷
export function getUsed(data) {
    return request({
      url: api+'getUsed',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}

// 获取已使用的优惠卷
export function getNewUser(data) {
    return request({
      url: '/system/coupons/getNewUser',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}