import request from '@/utils/request'

const userApi = '/webapi/user/'
const expApi = '/webapi/enterpriseExpert/'
const funcApi = '/webapi/enterpriseUserOpeningFunction/'
const memApi = '/webapi/userMember/'
//用户切换企业
export function changeUserEnterprise(data){
    return request({
        url: userApi+`changeEnterprise/${data.eNo}`,
        method: 'put',
        data:data
    })
}
//查询企业下所有用户
export function getUserEnterprise(data){
    return request({
        url: userApi+`getUserByEnterprise`,
        method: 'get',
        params:data
    })
}
//查询用户列表
export function getUserList(data){
    return request({
        url: userApi+`list`,
        method: 'post',
        headers: {
          'repeatSubmit': false
        },
        data:data
    })
}
//修改用户信息
export function updateUser(data){
    return request({
        url: userApi+`update`,
        method: 'put',
        data:data
    })
}
//获取当前用户的专家信息
export function getCur(){
    return request({
        url: expApi+`getCur`,
        method: 'get'
    })
}
//修改用户信息
export function updateCur(data){
    return request({
        url: expApi+`update`,
        method: 'put',
        data:data
    })
}
//解绑微信、钉钉、邮箱
export function unbind(data){
    return request({
        url: userApi+`unbind`,
        method: 'put',
        params:data
    })
}
//获取平台登录二维码链接
export function getLoginQR(source) {
  return request({
    url: userApi+`render/${source}`,
    method: 'get',
    // params:data
  })
}
/**
 * 员工开通的功能
*/
//开通功能列表
export function getOpenFuns(data){
    return request({
      url: funcApi+'getOpenFuns',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}
//开通功能列表
export function saveFuns(data){
  return request({
    url: funcApi+'save',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    data:data
  })
}
//续费会员
export function renewFun(data){
  return request({
    url: funcApi+'renew',
    method: 'put',
    data:data
  })
}
//续费会员
export function changeRenewStatus(data){
  return request({
    url: funcApi+'changeRenewStatus',
    method: 'put',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}

/**
 * 员工开通的会员
*/
//获取当前用户开通的会员
export function getOpenMember(data){
    return request({
      url: memApi+'getByUserNo',
      method: 'get',
      params:data
    })
}

//修改当前用户开通的会员
export function updateOpenMember(data){
    return request({
      url: memApi+'update',
      method: 'put',
      data:data
    })
}

//修改当前用户开通会员的自动续费状态
export function editIsAutoStatus(data){
  return request({
    url: memApi+'editIsAutoStatus',
    method: 'put',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}

//修改当前用户开通的会员
export function getMemberList(data){
    return request({
      url: '/system/platformMemberType/list',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      params:data
    })
}

//开通会员
export function openMember(data){
  return request({
    url: memApi+'joinMember',
    method: 'post',
    params:data
  })
}

//续费会员
export function renewMember(data){
  return request({
    url: memApi+'renew',
    method: 'put',
    data:data
  })
}

//获取可续费会员
export function getRenewMember(data){
  return request({
    url: memApi+'getMemberInfo',
    method: 'get',
    params:data
  })
}

//根据会员类型编码获取可以使用并且公司已开通的服务
export function getServerByMemberType(data){
  return request({
    url: memApi+'getServerByMemberType',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}

//根据会员类型编码获取需要付费的功能
export function getPayFunByMemberType(data){
  return request({
    url: memApi+'getPayFunByMemberType',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}

//根据会员类型编码获取免费的功能
export function getFreeFunByMemberType(data){
  return request({
    url: memApi+'getFreeFunByMemberType',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}

//根据会员类型编码获取免费的功能
export function getServerMember(data){
  return request({
    url: memApi+'getMemberTypes',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}
//根据会员类型编码获取免费的功能
export function getMemberByMemberType(data){
  return request({
    url: memApi+'getMemberByMemberType',
    method: 'get',
    params:data
  })
}

//会员转移
export function transferMember(data){
  return request({
    url: memApi+'transfer',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}

//会员转移
export function getCount(data){
  return request({
    url: memApi+'getCount',
    method: 'get',
    params:data
  })
}

//会员转移
export function updateAuthorization(data){
  return request({
    url: `/pro/auditProject/auditProjectCompanyAuthorization/${data.pNo}/${data.cId}/${data.isAuthorization}`,
    method: 'put',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}

