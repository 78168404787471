import request from '@/utils/request'

const api = '/webapi/pay/'

// 用户-地址信息列表
export function getPayInfo(data) {
    return request({
      url: api+'payment',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}
// 支付宝状态查询
export function tradeQueryByStr(data) {
    return request({
      // url: api+'tradeQueryByStr',
      url: '/webapi/accountTransaction/getByTno/'+data.tno,
      method: 'get',
      params:data
    })
}
// 微信支付状态查询
export function wxquery(data) {
    return request({
      url: api+'wxquery',
      method: 'get',
      params:data
    })
}