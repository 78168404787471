<template>
<div>
    <a-popover trigger="hover" v-if="isHidden" placement="topLeft">
        <template #title>
            {{title}}
        </template>
        <template #content>
            <div style="max-width:200px">
                {{content}}
            </div>
        </template>
        <div class="content" ref="content" :style="`width:${width}px;height:${height}px;-webkit-line-clamp:${num};${textStyle}`">
            {{content}}
        </div>
    </a-popover>
    <div v-else>
        <div class="content" ref="content" :style="`width:${width}px;height:${height}px;-webkit-line-clamp:${num};${textStyle}`">
            {{content}}
        </div>
        <div ref="newContent" v-if="isnew" style="position: absolute;visibility: hidden;">{{content}}</div>
    </div>
</div>
</template>
<script>
export default {
    props:{
        width:{
            type:String,
            default:''
        },
        height:{
            type:String,
            default:''
        },
        content:{
            type:String,
            default:''
        },
        title:{
            type:String,
            default:''
        },
        num:{
            type:String,
            default:'1'
        },
        textStyle:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            isHidden:false,
            isnew:true
        }
    },
    created(){
        this.$nextTick(()=>{
            this.isHidden = (this.$refs['newContent'].scrollWidth>this.$refs['content'].scrollWidth)
            this.isnew=false
        })
    }
}
</script>
<style lang="less" scoped>
.content{
    // display: inline-block;
    overflow:hidden;
    text-overflow: ellipsis;
    // -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
</style>