import { createRouter, createWebHistory } from 'vue-router'
import { message } from 'ant-design-vue';
import Layout from '@/layout'
import store from '@/store'
import warterMark from '@/utils/waterMark'; // 水印
import {encryPhone} from '@/utils/common'; // 水印手机号加密
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'project',
    component: Layout,
    children: [
      {
        path: '/project',
        name: 'project',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // meta:{title:"项目概览"},
        component: () => import(/* webpackChunkName: "about" */ '../views/project/projectView.vue')
      },
      {
        path: '/organize',
        name: 'organize',
        // meta:{title:"组织架构"},
        component: () => import('../views/organize/index.vue')
      },
      {
        path: '/user',
        name: 'user',
        // meta:{title:"个人设置"},
        component: () => import('../views/user/layout.vue'),
        children:[
          {
            path: 'userInfo',
            name: 'userInfo',
            // meta:{title:"个人设置"},
            component: () => import('../views/user/userInfo.vue')
          },
          {
            path: 'person',
            name: 'person',
            // meta:{title:"个人信息"},
            component: () => import('../views/user/information/person.vue')
          },
          {
            path: 'expert',
            name: 'expert',
            // meta:{title:"专家信息"},
            component: () => import('../views/user/information/expert.vue')
          },
          {
            path: 'safe',
            name: 'safe',
            // meta:{title:"安全设置"},
            component: () => import('../views/user/information/safe.vue')
          },
          {
            path: 'myAccount',
            name: 'myAccount',
            // meta:{title:"我的账户"},
            component: () => import('../views/user/account.vue')
          },
          {
            path: 'order',
            name: 'order',
            // meta:{title:"我的订单"},
            component: () => import('../views/user/order.vue')
          },
          {
            path: 'funcUser',
            name: 'funcUser',
            // meta:{title:"功能管理"},
            component: () => import('../views/user/funcUser.vue')
          },
          {
            path: 'invoice',
            name: 'invoice',
            // meta:{title:"发票管理"},
            component: () => import('../views/user/invoice/invoice.vue')
          },
          {
            path: 'invUp',
            name: 'invUp',
            // meta:{title:"抬头管理"},
            component: () => import('../views/user/invoice/invUp.vue')
          },
          {
            path: 'invRecord',
            name: 'invRecord',
            // meta:{title:"开票记录"},
            component: () => import('../views/user/invoice/record.vue')
          },
          {
            path: 'address',
            name: 'address',
            // meta:{title:"地址管理"},
            component: () => import('../views/user/address.vue')
          },
          {
            path: 'recommend',
            name: 'recommend',
            // meta:{title:"我的推荐"},
            component: () => import('../views/user/recommend.vue')
          }
        ]
      },
      {
        path: '/enterprise',
        name: 'enterprise',
        // meta:{title:"企业概览"},
        component: () => import('../views/enterprise/layout.vue'),
        children:[
          {
            path: 'info',
            name: 'info',
            // meta:{title:"企业概览"},
            component: () => import('../views/enterprise/info.vue')
          },
          {
            path: 'detail',
            name: 'detail',
            // meta:{title:"企业信息"},
            component: () => import('../views/enterprise/detail.vue')
          },
          {
            path: 'account',
            name: 'account',
            // meta:{title:"企业账户"},
            component: () => import('../views/enterprise/account.vue')
          },
          {
            path: 'news',
            name: 'news',
            // meta:{title:"企业新闻"},
            component: () => import('../views/enterprise/news.vue')
          },
          {
            path: 'notice',
            name: 'notice',
            // meta:{title:"企业公告"},
            component: () => import('../views/enterprise/notice.vue')
          },
        ]
      },
      {
        path: '/openServer',
        name: 'openServer',
        // meta:{title:"服务与功能"},
        component: () => import('../views/server/index.vue')
      },
      {
        path: '/myProject',
        name: 'myProject',
        // meta:{title:"我的项目"},
        component: () => import('../views/project/myProject.vue')
      },
      {
        path: '/fileProject',
        name: 'fileProject',
        // meta:{title:"归档项目"},
        component: () => import('../views/project/fileProject.vue')
      },
      {
        path: '/statistics',
        name: 'statistics',
        // meta:{title:"项目统计"},
        component: () => import('../views/project/statistics.vue')
      },
      {
        path: '/enterpriseList',
        name: 'enterpriseList',
        // meta:{title:"公司列表"},
        component: () => import('../views/project/enterprise.vue')
      },
      {
        path: '/message',
        name: 'message',
        // meta:{title:"消息列表"},
        component: () => import('../views/message.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    // meta:{title:"登录"},
    // meta:{title:"审计机器人"},
    component: () => import('../views/login.vue')
  },
  {
    path: '/help',
    name: 'help',
    // meta:{title:"帮助与文档"},
    component: () => import('../views/help.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    // meta:{title:"帮助与文档"},
    component: () => import('../views/pay.vue')
  },
  {
    path: '/jump',
    name: 'jump',
    // meta:{title:"权限验证跳转"},
    component: () => import('../views/jump.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== '/login'&& Object.keys(store.getters.user).length === 0) {
    let { data } = await store.dispatch('GetInfo')
    if(data.member==null){
      store.dispatch('LogOut').then(() => {
        location.href = '/login';
      })
      message.warning('该角色未被创建，请联系管理员！')
      return;
    }
    warterMark.set(data.member.realName+encryPhone(data.member.member.mobile))
  }
  if(to.path == '/login'){
    warterMark.set('')
  }
  if(to.meta.title){//判断是否有标题
    window.document.title = to.meta.title
  }
  next()
})


export default router
