import request from '@/utils/request'

const userApi = '/webapi/userEnterprise/'
const enterApi = '/webapi/enterprise/'
const assetsApi = '/webapi/enterpriseAssets/'
const serApi = '/webapi/enterpriseOpeningService/'
const expApi = '/webapi/enterpriseExpertService/'

// 用户-企业列表
export function getUserEntList(data) {
  return request({
    url: userApi+'list',
    method: 'post',
    params:data
  })
}
// 用户-企业列表
export function getEnterPriseInfo(data) {
  return request({
    url: enterApi+`getEnterPriseInfo/${data}`,
    method: 'get',
  })
}
// 按照条件查询企业
export function enterSearchOfName(data) {
  return request({
    url: enterApi+'enterpriseSearch',
    method: 'post',
    data:data
  })
}
// 按照条件查询企业
export function enterSearchOfEno(data) {
  return request({
    url: enterApi+`getByEno/${data}`,
    method: 'get',
  })
}
// 添加新企业
export function addEnterprise(data) {
  return request({
    url: enterApi+'enterpriseCreate',
    method: 'post',
    data:data
  })
}
// 修改企业信息
export function editEnterprise(data) {
  return request({
    url: enterApi+'enterpriseUpdate',
    method: 'put',
    data:data
  })
}
// 修改企业拥有者
export function editOwner(data) {
  return request({
    url: enterApi+'changeOwner',
    method: 'put',
    params:data
  })
}
// 修改企业管理员
export function editManager(data) {
  return request({
    url: enterApi+'changeManager',
    method: 'put',
    params:data
  })
}
//申请进入企业
export function applyOnEnterprise(data) {
  return request({
    url: userApi+'save',
    method: 'post',
    data:data
  })
}
//申请进入企业
export function applyCancelEnterprise(data) {
  return request({
    url: userApi+`cancel/${data}`,
    method: 'delete'
  })
}

/**
 * 企业空间资源
*/

//空间大小调整
export function adjustStorage(data) {
  return request({
    url: assetsApi+'adjustStorage',
    method: 'post',
    params:data
  })
}

//购买容量
export function buyStorage(data) {
  return request({
    url: assetsApi+'buyStorage',
    method: 'post',
    params:data
  })
}

//容量续费
export function renewStorage(data) {
  return request({
    url: assetsApi+'renewStorage',
    method: 'post',
    params:data
  })
}

/**
 * 企业开通服务
*/

// 企业开通服务
export function getEntOpenServerList(data) {
  return request({
    url: serApi+'list',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    data:data
  })
}
// 开启或关闭自动续费
export function changeRenewStatus(data) {
  return request({
    url: serApi+'changeRenewStatus',
    method: 'put',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}
// 首次免费开通服务
export function openingServer(data) {
  return request({
    url: serApi+'open',
    method: 'post',
    data:data
  })
}

// 续费服务
export function renewServer(data) {
  return request({
    url: serApi+'renew',
    method: 'put',
    data:data
  })
}


// 获取服务
export function getByServerCode(data) {
  return request({
    url: `/system/server/getByServerCode/${data.serverCode}`,
    method: 'get',
    params:data
  })
}
/**
 * 专家
*/

// 专家列表
export function getExpert(data) {
  return request({
    url: expApi+`getByCode`,
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}
// 专家列表
export function deleteExpert(id) {
  return request({
    url: expApi+`delete/${id}`,
    method: 'delete'
  })
}
// 专家列表
export function addExpert(data) {
  return request({
    url: expApi+`save`,
    method: 'post',
    data:data
  })
}