import { login, codeLogin, logout, getInfo, refreshToken } from '@/api/login'
import { getOpenMember } from '@/api/user'
import { getAccountUser  } from '@/api/account'
import { getInfoList } from '@/api/info'
import { getOrgManager } from '@/api/department'
import { getToken, setToken, setExpiresIn, removeToken } from '@/utils/auth'
const user = {
  state: {
    token: getToken(),
    user:{}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_EXPIRES_IN: (state, time) => {
      state.expires_in = time
    },
    SET_USR: (state, user) => {
      state.user = user
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        const {mobile, password} = userInfo
        login({mobile, password}).then(res => {
          let data = res.data
          setToken(data.access_token)
          commit('SET_TOKEN', data.access_token)
          setExpiresIn(data.expires_in)
          commit('SET_EXPIRES_IN', data.expires_in)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 扫码登录保存token
    qrLoginToken({ commit }, data) {
      setToken(data.access_token)
      commit('SET_TOKEN', data.access_token)
      setExpiresIn(data.expires_in)
      commit('SET_EXPIRES_IN', data.expires_in)
    },
    //验证码登录
    loginInCode({ commit }, userInfo){
      return new Promise((resolve, reject) => {
        const {mobile, code} = userInfo
        codeLogin({mobile, code}).then(res => {
          let data = res.data
          setToken(data.access_token)
          commit('SET_TOKEN', data.access_token)
          setExpiresIn(data.expires_in)
          commit('SET_EXPIRES_IN', data.expires_in)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(async res => {
          let user = res.data
          let member = await getOpenMember({userNo:user.userlName,eNo:user.member.eno})
          user.openMember = member.data
          // user.openMember = []
          // 加载账户信息
          let {data}= await getAccountUser()
          user.account = data
          // 加载系统消息
          let info = await getInfoList({ type:4, status:0 })
          user.info = info.rows
          //加载组织架构管理员信息
          let org = await getOrgManager({})
          user.org = org.data
          // user.account = {data}
          commit('SET_USR', user)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 刷新token
    RefreshToken({commit, state}) {
      return new Promise((resolve, reject) => {
        refreshToken(state.token).then(res => {
          setExpiresIn(res.data)
          commit('SET_EXPIRES_IN', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USR', {})
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
