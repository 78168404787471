import request from '@/utils/request'

const api = '/file/'
// 文件上传
export function upload(data) {
  return request({
    url: api+'upload',
    method: 'post',
    headers: { 'content-type': 'multipart/form-data' },
    data: data
  })
}