<template>
    <a-modal title="客户端下载" width="900px" v-model:visible="dialogVisible" centered :footer="null">
        <div style="text-align: center;">
            <div class="list">
                <div class="item">
                    <img :src="require('@/assets/Image/download/windows.png')" alt="">
                    <div class="name">Windows下载</div>
                    <div class="ver">版本：6.3</div>
                </div>
                <div class="item">
                    <img class="qrImg" :src="require('@/assets/Image/wx.png')" alt="">
                    <div class="name">Android下载</div>
                    <div class="ver">版本：6.3</div>
                </div>
                <div class="item" style="margin:0">
                    <img class="qrImg" :src="require('@/assets/Image/wx.png')" alt="">
                    <div class="name">iOS下载</div>
                    <div class="ver">版本：6.3</div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </a-modal>
</template>
<script>
import { list } from '@/mixins/list'
import { dialog } from '@/mixins/dialog'
// import { upload } from '@/api/file'
export default {
    mixins:[list,dialog],
    data(){
        return{
        }
    },
    methods:{
        diaInit(){
        },
    }
}
</script>
<style lang="less" scoped>
.btns{
    margin-top: 38px;
    text-align: center;
}
.list{
    padding: 20px;
    display: inline-block;
    .item{
        width: 240px;
        height: 260px;
        margin-right: 33px;
        background: #f5f8ff;
        float: left;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        &:hover{
            border: 1px solid #426be2;
        }
        img{
            width: 76px;
            height: 76px;
            display: inline-block;
            margin-top: 65px;
            margin-bottom: 40px;
        }
        .qrImg{
            width: 123px;
            height: 123px;
            margin-top: 40px;
            margin-bottom: 19px;
        }
        .name{
            font-size: 18px;
            color: #333333;
            margin: 0;
        }
        .ver{
            font-size: 12px;
            margin-top: 15px;
            color: #999999;
        }
    }
}
.clear{
    clear: both;
}
</style>