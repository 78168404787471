const permission = {
  state: {
    menu: [
        // {
        //     id:1,
        //     name:'首页',
        //     icon:'home',
        //     url:'/',
        //     chilren:[]
        // },
        {
            id:2,
            name:'项目',
            icon:'task',
            chilren:[
                {
                    name:'项目概括',
                    url:'/project',
                    icon:'project',
                    parentId:2
                },
                {
                    name:'我的项目',
                    url:'/myProject',
                    icon:'mine',
                    parentId:2
                },
                {
                    name:'归档项目',
                    url:'/fileProject',
                    icon:'pigeonhole',
                    parentId:2
                },
                {
                    name:'项目统计',
                    url:'/statistics',
                    icon:'statistics',
                    border:true,
                    parentId:2
                },
                {
                    name:'公司列表',
                    url:'/enterpriseList',
                    icon:'company',
                    parentId:2
                }
            ]
        },
    ],
    selectMenu:{
        all:1,
        menu:1
    },
    allLoading:false
  },
  mutations: {
    SET_MENU: (state, menu) => {
      state.menu = menu
    },
    SET_LOADING: (state, value) => {
      state.allLoading = value
    }
  },
  actions: {
    // 生成路由
    updateMenu({ commit }, menu) {
        commit('SET_MENU', menu)
    },
    // 修改加载状态
    updateLoading({ commit }, value) {
        commit('SET_LOADING', value)
    }
  }
}

export default permission
