<template>
<div>
    <a-modal :title="title!=''?title:isTransfer?'转账':isInput?'充值':'续费'" :bodyStyle="{padding: '35px',textAlign: 'center',color: '#333333'}" width="930px" v-model:visible="dialogVisible" centered :footer="null">
        <a-spin :spinning="loading">
             <!-- v-if="!isInput" -->
            <div class="money">
                支付金额：
                <span class="icon">￥</span>
                <span class="num">{{realMoney}}</span>
            </div>
             <!-- v-else -->
            <div v-if="active==4" class="money" style="margin-bottom:15px">
                余额：
                <span class="num" v-if="userOrEnter">{{user.account.accountMoney.toLocaleString('zh-CN', {style: 'currency', currency: 'CNY',})}}</span>
                <span class="num" v-else-if="account.accountMoney&&!userOrEnter">{{account.accountMoney.toLocaleString('zh-CN', {style: 'currency', currency: 'CNY',})}}</span>
            </div>
            <div class="inputMoney" v-if="isTransfer">
                <span>转账账户：</span>
                <a-select
                    v-model:value="form.toAno"
                    show-search
                    placeholder="请输入转账人手机号"
                    style="width: 150px"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    :options="userList"
                    @search="handleSearch"
                ></a-select>
            </div>
            <!-- <div class="inputMoney" v-if="isInput">
                <span>充值金额：</span>
                <a-input-number id="inputNumber" step="0.01" :min="0" style="width:130px" v-model:value="valueMoney" @change="moenyChage"/>
                <span style="margin-left:5px">元</span>
            </div> -->
            <div class="orderNo" v-if="!isTransfer&&orderNo!=''||!isInput&&orderNo!=''||isInput&&active!=1&&orderNo!=''">
                交易订单：<span>{{orderNo}}</span>
            </div>
            <div class="inputMoney" v-show="active==4">
                <span>支付账户：</span>
                <a-select show-search :filter-option="filterOption" 
                    v-model:value="form.fromAno" style="width:150px" placeholder="请选择支付账户" >
                    <a-select-option v-for="(item,index) in payAccouny" :key="index" :value="item.ano.toString()" :label="item.accountName?item.accountName:'企业用户'">{{item.accountName?item.accountName:'企业用户'}}</a-select-option>
                </a-select>
            </div>
            <div class="accountDetail" v-show="active == 1">
                <a-spin :spinning="platAccountLoading">
                    <div class="aDtitle">汇款账户信息</div>
                    <div class="aDitem">账户名称：{{platAccount.name}}</div>
                    <div class="aDitem">开户银行：{{platAccount.bank}}</div>
                    <div class="aDitem">银行账户：{{platAccount.account}}</div>
                </a-spin>
            </div>
            <div class="backQR" v-show="active!=4">
                <div class="title">{{active==1?'转账支付':active==2?'微信支付':active==3?'支付宝支付':'账户支付'}}</div>
                    <!-- v-model:fileList="fileList" -->
                    <!-- :multiple="true" -->
                <a-upload-dragger v-show="active==1"
                    ref="xlsx"
                    :before-upload="onBeforeUploadImage"
                    :on-remove="()=>{payVoucher=''}"
                    accept=".jpg, .png"
                    :limit="1" listType="text"
                    :customRequest="uploadImage">
                    <p class="ant-upload-drag-icon">
                        <img :src="require('@/assets/Image/pay/upload.png')" style="width: 74px;height: 60px;" alt="">
                    </p>
                    <p class="ant-upload-text">
                        将汇款凭证拖到此处，或
                        <span style="color:#4977FC">点击上传</span>
                    </p>
                    <p class="ant-upload-hint">支持 png、jpg格式，图片不能大于5M</p>
                </a-upload-dragger>
                <a-spin :spinning="qrLoading">
                    <!-- <img v-show="active!=1" :src="require('@/assets/Image/wx.png')" alt=""> -->
                    <div style="display: inline-block;width:200px;height:200px" v-if="qrLoading"></div>
                    <vueQr v-if="active==2&&payInfo.code_url" :margin='8' :size='200' :whiteMargin="true" :logoMargin="3" :logoCornerRadius="20" :text='payInfo.code_url'> </vueQr>
                    <!-- <vueQr v-if="active==3&&payInfo" :margin='8' :size='200' :whiteMargin="true" :logoMargin="3" :logoCornerRadius="20" :text='payInfo'> </vueQr> -->
                    <!-- <iframe :srcdoc="payInfo" v-if="active==3&&payInfo"
                            frameborder="no"
                            border="0"
                            marginwidth="0"
                            marginheight="0"
                            scrolling="no"
                            width="200"
                            height="200"
                            style="overflow:hidden;">
                    </iframe> -->
                    <div style="display: inline-block;width:200px;height:200px" ref="alipay" v-if="active==3&&payInfo" v-html="payInfo"></div>
                </a-spin>
                <div v-show="active!=1" class="tip">请打开手机{{active==2?'微信':'支付宝'}}</div>
                <div v-show="active!=1" class="tip">扫描二维码支付</div>
            </div>
            <div class="payType">
                <div class="header">支付方式</div>
                <div class="list">
                    <div v-if="!isTransfer" :class="active==2?'item active':'item'" @click="active = 2;changePayType()">
                        <img :src="require('@/assets/Image/pay/wechat.png')" alt="">
                    </div>
                    <div v-if="!isTransfer" :class="active==3?'item active':'item'" @click="active = 3;changePayType()">
                        <img :src="require('@/assets/Image/pay/AliPay.png')" alt="">
                    </div>
                    <div v-if="!isTransfer" :class="active==1?'item active':'item'" @click="active = 1;changePayType()">
                        <img style="height:51px" :src="require('@/assets/Image/pay/zhPay.png')" alt="">
                    </div>
                    <div v-if="!isInput" :class="active==4?'item active':'item'" @click="active = 4;changePayType()">
                        <img style="height:51px" :src="require('@/assets/Image/pay/xtzhPay.png')" alt="">
                    </div>
                </div>
            </div>
            <div class="subBtn" v-show="active == 1||active == 4">
                <a-button type="primary" @click="submitPay">确认支付</a-button>
            </div>
        </a-spin>
    </a-modal>
    <a-modal title="支付状态" width="500px" v-model:visible="statusPayVisible" centered :footer="null">
        <div class="payStatus">
            <img :src="require(status?'@/assets/Image/pay/success.png':'@/assets/Image/pay/failure.png')" alt="">
            <div class="status">
                <div class="title">{{status?'支付成功':'支付失败'}}</div>
                <div v-if="status" class="success">
                    <span class="sicon">￥</span>
                    <span v-if="payTrade.amount">{{active==2? parseFloat((payTrade.amount.total/100).toFixed(10)):payTrade.amount.total}}</span>
                    <span v-else>{{money}}</span>
                </div>
                <div v-else class="fail">支付遇到问题，请常使重新支付</div>
            </div>
            <div class="contentCard">
                <div class="item">
                    <div class="label">交易订单：</div>
                    <div class="info">{{payTrade.out_trade_no?payTrade.out_trade_no:orderNo}}</div>
                </div>
                <div class="item">
                    <div class="label">交易时间：</div>
                    <div class="info">{{new Date().toLocaleDateString()}}</div>
                </div>
                <div class="item" style="margin:0px">
                    <div class="label">交易方式：</div>
                    <div class="info">{{active==1?'转账支付':active==2?'微信支付':active==3?'支付宝支付':'账户支付'}}</div>
                </div>
            </div>
            <div class="footer" v-if="status">
                请在
                <span>个人设置</span>
                页面查看会员权益
            </div>
        </div>
    </a-modal>
</div>
</template>
<script>
import { getUserEnterprise,getUserList } from '@/api/user'
import { getAccountEnter, rechangeAccount, transferAccount, getPayAccount, getPayAccountByPhone,editTranStatus,editTran,getPlatAccount } from '@/api/account'
import { getPayInfo, tradeQueryByStr, wxquery } from '@/api/pay'
import { list } from '@/mixins/list'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { upload } from '@/api/file'
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        vueQr
    },
    mixins:[list],
    props:{
        // 标题
        title:{
            type:String,
            default:''
        },
        // 订单编号
        money:{
            type:String,
            default:'0'
        },
        // 弹出框显示
        visible:{
            type:Boolean,
            default:false
        },
        // 是否充值
        isInput:{
            type:Boolean,
            default:false
        },
        // 是否转账
        isTransfer:{
            type:Boolean,
            default:false
        },
        // 订单编号
        orderNo:{
            type:String,
            default:''
        },
        //企业或个人
        userOrEnter:{
            type:Boolean,
            default:true //true个人
        },
        // 支付所需信息
        payObj:{
            type:Object,
            default:()=>null
        },
        //账户付款是否包含企业 默认1包含 0不包含
        containsEnterprise:{
            type:String,
            default:'1'
        }
    },
    model: {
      prop: 'visible'
    },
    data() {
        return {
            dialogVisible: this.visible,
            statusPayVisible:false,
            status:false,
            active:2,
            value:1,
            userList:[],
            form:{
                toAno:null,
                fromAno:''
            },
            account:{},
            loading:false,
            qrLoading:false,
            payInfo:{},
            valueMoney:1,
            realMoney:0,
            payDetail:this.payObj,
            payAccouny:[],
            timer:null,
            payTrade:{},
            platAccount:{},
            platAccountLoading:false,
            payVoucher:'',
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)   //结束定时器，
        // console.log(this.timer, "销毁组件");
    },
    methods:{
        async diaInit(){
            this.realMoney = this.money
            // console.log(this.money)
            // this.valueMoney = this.money
            this.form = {
                toAno:null,
                fromAno:''
            }
            this.active = this.payObj && this.payObj.payType!=null ? this.payObj.payType : 2
            this.payDetail = this.payObj
            if(!this.isInput){
                // 支付
                this.changePayType()
            }else{
                if(this.isTransfer){
                    this.active=4
                    this.realMoney = this.valueMoney
                    this.changePayType()
                }else{
                    //充值记录添加
                    this.valueMoney = this.realMoney
                    rechangeAccount({ano:this.userOrEnter?this.user.userlName:this.user.member.eno,money:this.realMoney}).then(res => {
                        // console.log(res.data)
                        this.payDetail = res.data
                        console.log(res.data)
                        this.changePayType()
                    })
                }
            }
            //汇款账户信息查询
            if(this.userOrEnter){

            }else{
                this.loading = true
                getAccountEnter().then(async res=>{
                    this.account = res.data
                    console.log(this.account)
                    this.loading = false
                })
            }
        },
        //支付完成后回调
        submitPay(){
            //银行卡支付
            if(this.active==1){
                console.log(this.payVoucher)
                if(this.payVoucher==''){
                    this.$message.warning('凭证不能为空!')
                    return;
                }
                let {tno} = this.payDetail
                // console.log(this.detail)
                editTran({ payVoucher: this.payVoucher,tno }).then(res => {
                    this.$message.success('上传成功, 请等待工作人员确定!')
                    this.dialogVisible = false
                })
                return;
            }
            //是否转账判断
            if(this.isTransfer){
                let { fromAno, toAno } = this.form
                transferAccount({money:this.realMoney, fromAno, toAno}).then(res => {
                    // console.log(res.data)
                    this.payDetail = res.data
                    console.log(res.data)
                    this.changePayType()
                })
            }else{
                editTranStatus({tno:this.payDetail.tno,status:1}).then(res => {
                    this.status = true
                    this.$emit('submitPay', this.status, this.active)
                    this.statusPayVisible=true
                })
            }
        },
        // 微信回调
        wxChatPayStatus(){
            this.timer = setInterval(()=>{
                wxquery({outTradeNo:this.payDetail.tno}).then(res => {
                    if(res.trade_state=='SUCCESS'){
                        this.payTrade = res
                        clearInterval(this.timer)   //结束定时器，
                        editTranStatus({tno:this.payDetail.tno,status:1}).then(res => {
                            this.status = true
                            this.statusPayVisible=true
                            this.$emit('submitPay', this.status, this.active)
                            this.refresh()
                        })
                        // console.log(this.timer, "销毁组件");
                    }
                })
            }, 3000)
        },
        // 支付宝回调
        aliPayStatus(){
            this.timer = setInterval(()=>{
                tradeQueryByStr({tno:this.payDetail.tno}).then(res => {
                    // console.log(res)
                    this.payTrade = res.data
                    this.payTrade.amount.total = res.data.money
                    this.payTrade.out_trade_no = res.data.tno
                    this.status =true
                    this.statusPayVisible = true
                    clearInterval(this.timer)   //结束定时器，
                    this.refresh()
                    // if(res.alipay_trade_query_response.code=='10000'){
                    //     this.status =true
                    //     this.statusPayVisible = true
                    //     this.payTrade = res
                    //     clearInterval(this.timer)   //结束定时器，
                    //     this.refresh()
                    //     // console.log(this.timer, "销毁组件");
                    // }
                })
            }, 3000)
        },
        //支付方式加载
        changePayType(){
            // console.log(this.active)
            this.payVoucher = ''
            clearInterval(this.timer)   //结束定时器，
            switch(this.active){
                case 1:
                    this.platAccountLoading = true
                    getPlatAccount().then(res => {
                        this.platAccount = res.data
                        this.platAccountLoading = false
                    })
                    break;
                case 2:
                    this.qrLoading = true
                    this.payInfo={}
                    console.log(this.payDetail)
                    this.payDetail.payType = 2
                    getPayInfo(this.payDetail).then(res => {
                        this.payInfo = JSON.parse(res.data)
                        this.wxChatPayStatus()
                        // console.log()
                        this.qrLoading = false
                    })
                    break;
                case 3:
                    this.qrLoading = true
                    this.payInfo=null
                    this.payDetail.payType = 3
                    getPayInfo(this.payDetail).then(res => {
                        // this.payInfo = res
                        this.aliPayStatus()
                        // console.log(res)
                        let m = res.replace("<script>document.forms[0].submit();",`<iframe
                            frameborder="no"
                            border="0"
                            marginwidth="0"
                            marginheight="0"
                            scrolling="no"
                            width="200" name='myFrame'
                            height="200">
                            </iframe>`)
                        m = m.replace(`name="punchout_form"`,`name="punchout_form" target="myFrame" id="formqr"`)
                        m = m.replace(`<\\script>`,``)
                        this.payInfo = m
                        // console.log(this.payInfo)
                        this.$nextTick(()=>{
                            console.log(document.getElementById('formqr'))
                            document.getElementById('formqr').submit();
                            setTimeout(() => {
                                this.qrLoading = false
                            }, 1000);
                        })
                    })
                    break;
                case 4:
                    getPayAccount({containsEnterprise:this.containsEnterprise}).then(res => {
                        this.payAccouny = res.data
                        this.form.fromAno = res.data[0].ano
                    })
                    break;
            }
        },
        // 充值金额改变
        moenyChage(val){
            if(val){
                if(this.payDetail){
                    this.payDetail.total = val
                }
                this.realMoney = val
            }else{
                this.valueMoney = 1
                this.realMoney = 1
                this.payDetail.total = 1
            }
            this.payDetail.description = this.payDetail.ano+"账户充值："+this.payDetail.total
            this.changePayType()
        },
        // 转账账户查询
        handleSearch(val){
            if(val){
                getPayAccountByPhone({phone:val}).then(res => {
                    this.userList = res.data.map(item => {
                        return {
                            label:item.accountName,
                            value:item.ano
                        }
                    })
                })
            }else{
                this.userList = []
            }
        },
        // 5秒后刷新当前页面
        refresh(){
            let than = this
            setTimeout(function() {
                if(than.statusPayVisible){
                    console.log('刷新')
                    than.$router.go(0)
                }
            }, 5000);
        },
        
        /**
         * 文件上传
        */
        //文件上传前
        onBeforeUploadImage(file) {
            const isIMAGE = /\.(jpg|png)$/.test(file.name)
            if (!isIMAGE) {
                this.$message.error('只允许上传jpg、png格式的图片!')
            }
            const isLt1M = file.size / 1024 / 1024 < 5
            if (!isLt1M) {
                this.$message.error('上传文件大小不能超过 5MB!')
            }
            return isIMAGE && isLt1M
        },
        //上传文件
        uploadImage(params) {
            const _file = params.file;
            var formData = new FormData();
            formData.append("file", _file);
            upload(formData).then(res => {
                if(res.code==200){
                    // console.log(res)
                    this.payVoucher = res.data.url
                    // this.draftForm.fileOssPath = res.data.path
                    params.onSuccess()
                }else{
                    params.onError()
                }
            }).catch(response => {
                params.onError()
            })
        }
    },
    computed:{
        ...mapGetters(["user"]),
    },
    watch: {
      dialogVisible(value) {
        // console.log('子组件下拉框值发生改变：', this.dialogVisible)
        this.$emit('update:visible', this.dialogVisible)
        if(value){
            this.diaInit()
        }else{
            clearInterval(this.timer)   //结束定时器，
            console.log(this.timer, "销毁组件");
        }
      },
      visible(value){
        this.dialogVisible = value
      }
    }
}
</script>
<style lang="less" scoped>
@import '@/assets/css/common.less';
.money{
    color: #333333;
    span{
        color: #FF1E1E;
        font-weight: bold;
    }
    .num{
        font-size: 30px;
    }
}
.inputMoney{
    margin-top: 8px;
    margin-bottom: 15px;
    span{
        display: inline-block;
        line-height: 32px;
    }
}
.orderNo{
    margin-bottom: 15px;
    font-size: 12px;
    span{
        color: #999999;
    }
}
.accountDetail{
    margin-bottom: 25px;
    text-align: left;
    padding-left: 15px;
    .aDtitle{
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .aDitem{
        margin-bottom: 15px;
        padding-left: 35px;
    }
}
.backQR{
    padding: 25px;
    background-color: #F4F5F6;
    font-size: 16px;
    .title{
        text-align: left;
    }
    img{
        width: 200px;
        height: 200px;
        margin: 20px 0px;
    }
}
.payType{
    padding: 20px 25px;
    .header{
        font-size: 16px;
        text-align: left;
        margin-bottom: 20px;
    }
    .list{
        display: flex;
        justify-content: space-between;
        .item{
            width: 190px;
            height: 76px;
            border: 1px solid #dedfe0;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            text-align:center;
            img{
                width: 130px;
                height: 42px;
                margin:0 auto;
            }
            &:hover{
                box-shadow: 0px 1px 23px 0.9px rgba(216,216,216,0.60); 
            }
        }
        .active{
            background: url('@/assets/Image/pay/selected.png') no-repeat;
            background-size:100% 100%;
            border: none;
            &:hover{
                box-shadow: none;
            }
        }
    }
}
.subBtn{
    text-align: right;
    padding-right:25px;
    margin-top:10px;
    button{
        width: 120px;
        height: 46px;
        font-size: 18px;
    }
}
/deep/ .ant-upload.ant-upload-drag{
    border: none;
    background-color: #F4F5F6;
}
.payStatus{
    text-align: center;
    img{
        width: 35px;
        height: 35px;
        margin-bottom: 15px;
    }
    .status{
        margin-bottom: 20px;
        .title{
            font-size: 16px;
        }
        .success{
            color: #FF1E1E;
            font-size: 30px;
            font-weight: bold;
            .sicon{
                font-size: 14px;
            }
        }
        .fail{
            font-size: 12px;
            margin-top: 8px;
            color: #999999;
        }
    }
    .contentCard{
        width: 297px;
        background: #f4f5f6;
        border-radius: 6px;
        margin: 0px auto;
        margin-bottom: 23px;
        padding: 23px 33px;
        .item{
            display: flex;
            margin-bottom: 22px;
            font-size: 12px;
            .label{
                text-align: left;
                flex: 0 0 30%;
            }
            .info{
                flex: 0 0 70%;
                text-align: right;
                color: #999999;
            }
        }
    }
    .footer{
        color: #333333;
        span{
            color: #4977FC;
            cursor: pointer;
            &:hover{
                color: #2b469a;
            }
        }
    }
}
/deep/ .ant-upload-list-item-name{
    text-align: left;
}
</style>