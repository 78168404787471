import { mapGetters, mapActions } from "vuex";
export const list = {
  data(){
    return {
      loading:false,
      btnLoading:false,
      tableLoading:false,
      list:[],
      page:{
          pageNum:1,
          pageSize:10,
          total:0,
      },
      selectedRowKeys:[],
      selectedRows:[],
      detail:{}
    }
  },
  created(){
    this.init()
  },
  methods:{
    ...mapActions(['updateLoading']),
    init(){

    },
    //跳转
    tolink(url,param){
      if(param){
        this.$router.push({path: url,query:param});
      }else{
        this.$router.push({path: url});
      }
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  },
  computed: {
    ...mapGetters(["user"]),
    rowSelection() {
      return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
              this.selectedRowKeys = selectedRowKeys;
              this.selectedRows = selectedRows
              // 选中的信息
              // console.log(selectedRows)
          },
      };
    },
  }
}
