import request from '@/utils/request'

const userApi = '/webapi/user/'
const authApi = '/auth'

// 登录方法
export function login(data) {
  return request({
    url: authApi+'/memberlogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data:data
  })
}
// 刷新方法
export function refreshToken() {
  return request({
    url: authApi+'/memberrefresh',
    method: 'post',
    data:data
  })
}
// 退出方法
export function logout(data) {
  return request({
    url: authApi+`/memberlogout`,
    method: 'delete'
  })
}
// 发送验证码 类型：0-注册 1-找回密码 2-登录
export function sendCode(data) {
  return request({
    url: `/webapi/sms/captchaSms`,
    method: 'get',
    params:data
  })
}
//注册
export function register(data) {
  return request({
    url: userApi+'regist',
    headers: {
      isToken: false
    },
    method: 'post',
    data:data
  })
}
//根据手机号判断用户是否存在
export function validatePhone(data) {
  return request({
    url: userApi+`validatePhone/${data}`,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}
// 获取用户信息
export function getInfo() {
  return request({
    url:  userApi+`getInfo`,
    method: 'get'
  })
}
// 验证码验证
export function validateCode(data) {
  return request({
    url:  userApi+`forgetPwd`,
    method: 'put',
    params:data
  })
}
// 验证码验证
export function codeLogin(data) {
  return request({
    url:  authApi+`/memberPhoneLogin`,
    method: 'post',
    data:data
  })
}
//找回密码第一步
export function forgetPwdOfOne(data){
  return request({
    url:  userApi+`/forgetPwd`,
    method: 'put',
    params:data
  })
}
//找回密码第二步
export function forgetPwdOfTwo(data){
  return request({
    url:  userApi+`/resetPwd`,
    method: 'put',
    params:data
  })
}
//获取平台登录二维码链接
export function getLoginQR(source,data) {
  return request({
    url: authApi+`/render/${source}`,
    method: 'get',
    params:data
  })
}
//扫码登录回调
export function qRCallback(data) {
  return request({
    url: authApi+`/back`,
    method: 'get',
    headers: {
      isErrorMsg: false
    },
    params:data
  })
}

//获取平台绑定二维码链接
export function getLoginBindQR(source,data) {
  return request({
    url: userApi+`/render/${source}`,
    method: 'get',
    params:data
  })
}
//绑定扫码登录回调
export function qrRBindCallback(data) {
  return request({
    url: userApi+`/back`,
    method: 'get',
    headers: {
      isErrorMsg: false
    },
    params:data
  })
}

//绑定扫码登录回调
export function getTransferUrl(data) {
  return request({
    url: `/system/platformUrl/transferUrl`,
    method: 'post',
    headers: {
      isErrorMsg: false
    },
    params:data
  })
}
