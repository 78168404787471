<template>
    <a-modal title="选择用户" width="500px" v-model:visible="dialogVisible" centered :footer="null">
        <div class="formDialog" v-if="dialogVisible">
                <a-form ref="select" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                    <a-form-item label="用户：" name="selectValue">
                        <a-select show-search :filter-option="filterOption" :mode="mode"
                            v-model:value="form.selectValue" style="width: 100%;" placeholder="请选择用户" >
                            <a-select-option v-for="(item,index) in this.userList" :key="index" :value="item.userNO.toString()" :label="item.name?item.name:'企业用户'">{{item.name?item.name+ ' - ' +item.organizational.departmentName:'企业用户'}}</a-select-option>
                        </a-select>
                        <!-- <a-select :mode="mode"
                            v-model:value="form.selectValue"
                            show-search
                            placeholder="请选择用户"
                            style="width: 100%;"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @search="handleSearch">
                            <a-select-option v-for="(item,index) in this.userList" :key="index" :value="item.userNO.toString()" :label="item.name?item.name:'企业用户'">{{item.name?item.organizational.departmentName+item.name:'企业用户'}}</a-select-option>
                        </a-select> -->
                    </a-form-item>
                </a-form>
            </div>
        <div class="btns">
            <a-button :loading="btnLoading" type="primary" @click="submitSelect">确定</a-button>
        </div>
    </a-modal>
</template>
<script>
import { list } from '@/mixins/list'
import { dialog } from '@/mixins/dialog'
import { getUserEnterprise } from '@/api/user'
import { getDepartmentUser } from '@/api/department'
export default {
    mixins:[list,dialog],
    props:{
        mode:{
            type:String,
            default:''
        },
        value:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return{
            form:{
                selectValue:[],
            },
            rules:{
                selectValue:[{ required: true, message: '请选择用户', trigger: 'blur' }],
            },
            userList:[],
            oldUserList:[]
        }
    },
    methods:{
        diaInit(){
            if(this.mode=='multiple'){
                this.form.selectValue=this.value
            }else{
                this.form.selectValue=this.value[0]
            }
            // getUserEnterprise({eNo:this.user.member.eno}).then(res => {
            //     this.userList = res.rows
            // })
            getDepartmentUser({status:1, pageNum:1, pageSize:9999}).then(res => {
                console.log(res.rows)
                this.userList = res.rows.map(item => {
                    item.userNO = item.userNo
                    item.name = item.employeesName
                    return item
                })
                this.oldUserList = res.rows.map(item => {
                    item.userNO = item.userNo
                    item.name = item.employeesName
                    return item
                })
                // this.departUserList = res.rows
                // this.page.total = res.total
                // this.updateLoading(false)
                // this.loading = false
            })
        },
        handleSearch(val){
            console.log(val)
            if(val){
                getDepartmentUser({status:1, pageNum:1, pageSize:9999,departmentId:0,name:val}).then(res => {
                    this.userList = res.rows.map(item => {
                        item.userNO = item.userNo
                        item.name = item.employeesName
                        return item
                    })
                })
            }else{
                this.userList = [...this.oldUserList]
            }
        },
        submitSelect(){
            this.$refs.select.validate().then(() => {
                let value;
                if(this.mode=='multiple'){
                    value=[]
                    for(let no of this.form.selectValue){
                        let item = this.userList.find(u => u.userNO==no)
                        value.push(item)
                    }
                }else{
                    value = this.userList.find(u => u.userNO==this.form.selectValue)
                }
                this.$emit('submitSelect',value)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.btns{
    margin-top: 38px;
    text-align: center;
}
</style>