<template>
    <a-modal width="1185px" v-model:visible="dialogVisible" centered :closable="false" :footer="null">
        <template #title>
            <a-avatar :size="46" :src="user.member.member.headPortrait==null?'':user.member.member.headPortrait" class="avatar">
                {{user.member.member.headPortrait==null?user.realName.charAt(0):''}}
            </a-avatar>
            <div class="name">{{user.realName}}</div>
            <!-- <div class="date" v-if="isMember">{{openMember.expiryDate}}到期，续费后有效顺延</div> -->
            <div class="close">
                <close-outlined @click="dialogVisible=false" />
            </div>
            <div class="clear"></div>
        </template>
        <a-spin :spinning="loading">
            <div class="memberType">
                <div :class="selected==index?'active item':'item'" v-for="(item,index) in memberList"
                    :key="index" :style="(index+1)%3==0?'margin-right:0px':''" @click="changeSelect(index)">
                    <div class="content">
                        <img :src="item.icon" alt="">
                        <!-- <img v-if="index==1" :src="require('@/assets/Image/popover/zhy.png')" alt="">
                        <img v-if="index==2" :src="require('@/assets/Image/popover/fhy.png')" alt=""> -->
                        <div class="money">
                            <!-- <div class="member-name">{{item==1?'审计会员':item==2?'咨询会员':'信息化会员'}}</div> -->
                             <!-- :style="item.curPrice||item.freeMonthNum<=0?'':'margin-top:6px'" -->
                            <div class="member-name">{{item.memberTypeName}}</div>
                            <!-- <div class="real" v-if="item.curPrice||item.freeMonthNum<=0"> -->
                            <div class="real" v-if="openFindMember(index)">
                                <span>￥</span>{{item.curPrice}}元/年
                                <span class="pri" v-if="item.curPrice!=item.price">￥{{item.price}}</span>
                            </div>
                            <div class="free" v-else style="font-size: 16px;margin-top:5px">
                                免费领取{{item.freeMonthNum}}个月
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="date" v-if="openFindMember(index)">{{openFindMember(index).expiryDate}}到期</div>
                        <div class="date" style="color: #999999;" v-else>试用结束后恢复{{item.price}}元/年</div>
                        <div class="clear"></div>
                        <div class="date" style="color: #999999;" v-if="openFindMember(index)">12个月</div>
                        <!-- <div class="date" style="color: #999999;" v-if="item.curPrice||item.freeMonthNum<=0">12个月</div> -->
                    </div>
                    <check-outlined class="check" />
                </div>
                <div class="clear"></div>
            </div>
            <div class="server">
                <div style="margin-bottom:8px">享受会员服务</div>
                <div>
                    <div class="server-item" :key="index" v-for="(item,index) in server">
                        <span></span> {{item.serverTitle}}
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <!-- selectObj.curPrice||selectObj.freeMonthNum<=0 -->
            <div class="renew-item" v-if="openFindMember(memberList.findIndex(m => m.id==selectObj.id))">
                <div class="header">会员权益</div>
                <a-checkbox v-for="(item,index) in freeFunc" :key="index" disabled v-model:checked="item.checked">
                    <!-- {{item.functionClassTitle}} -->
                    {{item.functionTitle}}
                    <!-- ￥{{item.price}} -->
                    <!-- <span class="pri">原价￥{{item.price}}</span> -->
                </a-checkbox>
            </div>
            <div class="renew-item" v-if="openFindMember(memberList.findIndex(m => m.id==selectObj.id))">
                <div class="header">更多权益</div>
                <div v-for="(item,index) in funcList" :key="index" style="float:left">
                    <a-checkbox v-model:checked="item.checked">
                        {{item.functionTitle}} ￥{{item.curPrice}}
                        <span class="pri" style="margin: 0px" v-if="item.curPrice!=item.price">原价￥{{item.price}}</span>
                    </a-checkbox>
                    <!-- <div class="btn" @click="couponVisible = true">领卷</div> -->
                </div>
                <div class="clear"></div>
            </div>
            <div class="renew-item" v-if="openFindMember(memberList.findIndex(m => m.id==selectObj.id))&&!isVvip">
                <div class="header">为其他会员续费</div>
                <div class="add">
                    <div style="float:left">
                        添加账号：
                    </div>
                    <personnel style="float:left" v-model:value="dataList"></personnel>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="moneyBack" v-if="openFindMember(memberList.findIndex(m => m.id==selectObj.id))&&!isVvip">
                <div class="lrAll">
                    <div class="left">价格：</div>
                    <div class="right">
                        ￥{{allPrice-allCouponMoeny<0?0:parseFloat((allPrice-allCouponMoeny).toFixed(10))}}
                        <span class="pri" v-if="allMoney!=allPriceMany">原价￥{{allPriceMany}}</span>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="lrAll">
                    <div class="left">数量：</div>
                    <div class="right">×{{this.dataList.length}}</div>
                    <div class="clear"></div>
                </div>
                <div class="lrAll">
                    <div class="left">优惠券</div>
                    <div class="right">
                        <div class="couponMoney">-￥{{allCouponMoeny}}</div>
                        <div class="couponInput" :style="this.selectCoupon.length<=0?'':'padding-bottom:0'" @click="openCouponSelect">
                            {{this.selectCoupon.length<=0?'点击选择优惠券':''}}
                             <a-tag class="tag" v-for="(item,index) in this.selectCoupon" :key="index" color="#6889EA">{{item.title}}</a-tag>
                            <!-- 满500减100 -->
                            <!-- selectCoupon -->
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <!-- <div class="lrAll">
                    <div class="left">是否使用积分</div>
                    <div class="right">
                        <a-switch style="float: left;margin-top: 1.5px;" v-model:checked="checked" />
                        <span style="margin-left:15px">当前拥有积分：1000，可抵消￥10</span>
                        <span class="jfbtn" style="margin-left:17px;color: #3c69eb;">（积分使用说明）</span>
                    </div>
                    <div class="clear"></div>
                </div> -->
            </div>
            <div class="allMoney" v-if="openFindMember(memberList.findIndex(m => m.id==selectObj.id))">
                <div class="checkLeft">
                    <a-checkbox v-model:checked="value">
                        同意并接受
                    </a-checkbox>
                    <span>《审计平台会员条款》</span>
                </div>
                <div class="moenyRight">
                    <span>合计：</span>
                    <span class="fmoney">￥</span>
                    <span class="nowMoney">
                        <!-- {{allPrice}} -->
                        {{allPrice-allCouponMoeny<0?0:parseFloat((allPrice-allCouponMoeny).toFixed(10))}}
                    </span>
                    <span class="realMoney">省￥{{allSPrice+allCouponMoeny}}</span>
                    <span class="yhbtn" @click="mDetailVisible=true">优惠明细</span>
                    <a-button type="primary" class="pribtn" @click="goPay">确认支付</a-button>
                </div>
                <div class="clear"></div>
            </div>
            <div class="allMoney" style="margin-top:23px" v-else>
                <div style="line-height: 23px;margin:0px 0px 40px 24px">免费享受会员权益，有效期{{selectObj.freeMonthNum*30}}天</div>
                <div class="checkLeft">
                    <a-checkbox v-model:checked="value">
                        同意并接受
                    </a-checkbox>
                    <span>《审计平台会员条款》</span>
                </div>
                <div class="moenyRight">
                    <a-button type="primary" class="pribtn" @click="goPay">立即领取</a-button>
                </div>
                <div class="clear"></div>
            </div>
        </a-spin>
        <moneyDetail v-model:visible="mDetailVisible"></moneyDetail>
        <coupon v-model:visible="couponVisible"></coupon>
        <couponSelect :searchParam="couponParam" v-model:visible="couponSelectVisible" :value="selectCoupon" @submitSelectCoupon="couponSubmit"></couponSelect>
        <pay v-model:visible="payVisible" title="支付" :payObj="payObj" :money="allPrice-allCouponMoeny<0?0:parseFloat((allPrice-allCouponMoeny).toFixed(10))" @submitPay="submitPay" :userOrEnter="true" containsEnterprise="0"></pay>
    </a-modal>
</template>
<script>
import personnel from './components/personnel.vue'
import moneyDetail from './components/moneyDetail.vue'
import coupon from './components/coupon.vue'
import couponSelect from '../../components/couponSelect'
import pay from '@/components/pay'
import { list } from '@/mixins/list'
import { getMemberList, openMember, renewMember, getRenewMember, getServerByMemberType, getPayFunByMemberType, getFreeFunByMemberType,getServerMember } from '@/api/user'
export default {
    mixins:[list],
    components:{
        personnel,moneyDetail,coupon,pay,couponSelect
    },
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        renewActive:{
            type:String,
            default:''
        },
        valueList:{
            type:Array,
            default:()=>[]
        }
    },
    model: {
      prop: 'visible'
    },
    data() {
      return {
        dialogVisible: this.visible,
        couponSelectVisible:false,
        selected:0,
        options: [
            { label: '功能一', value: '1' },
            { label: '功能二', value: '2' },
            { label: '功能三', value: '3' },
        ],
        value:false,
        dataList:[],
        checked:false,
        mDetailVisible:false,
        couponVisible:false,
        memberList:[],
        payObj:{},
        payVisible:false,
        server:[],
        funcList:[],
        freeFunc:[],
        selectCoupon:[],
        couponParam:{}
      }
    },
    methods:{
        //弹窗打开加载
        diaInit(){
            this.loading = true
            this.dataList=[]
            this.selectCoupon=[]
            // console.log(this.dataList)
            this.dataList=this.valueList.concat()
            // console.log(this.dataList)
            if(!this.dataList.find(item => item.userNO == this.user.member.member.userNO)){
                this.dataList.push({
                    userid:this.user.member.member.userid,
                    userNO:this.user.member.member.userNO,
                    // name:this.user.member.member.name==''?'企业用户':this.user.member.member.name,
                    name:this.user.member.employees.employeesName==''?'企业用户':this.user.member.employees.employeesName,
                    url:this.user.employees.headPortrait
                })
            }
            getServerMember({}).then(async res => {
                // console.log(res)
                this.memberList = res.data.filter(item => item.isShow == 1)
                if(this.memberList.length<=0){
                    this.$message.warning("请先开通服务再开通会员")
                    this.dialogVisible = false
                    this.tolink("/openServer")
                    return;
                }
                let {data} = await getRenewMember({eNo:this.user.member.eno,userNo:this.user.userlName})
                for(let item of data){
                    let index = this.memberList.findIndex(m => m.memberTypeNo == item.memberTypeNo)
                    // console.log(index)
                    if(index!=-1){
                        // console.log(item)
                        for(let key in item){
                            this.memberList[index][key] = item[key]
                        }
                    }
                }
                console.log(this.memberList)
                if(this.renewActive!=''){
                    let index = this.memberList.findIndex(m => m.memberTypeNo == this.renewActive)
                    this.selected = index ==-1 ? 0:index
                }else{
                    this.selected = 0
                }
                this.changeSelect(this.selected)
            })
        },
        //续费和领取
        goPay(){
            // console.log(this.funcList)
            if(!this.value){
                this.$message.warning('请先阅读并同意会员条款!')
                return;
            }
            if(this.isKTmember!=undefined){
                let list =[]
                let free = this.freeFunc.filter(f => f.checked)
                let func = this.funcList.filter(f => f.checked)
                list.push(...free)
                list.push(...func)
                let dlist = this.dataList.map(item => {return item.userNO})
                if(dlist.length<=0){
                    this.$message.warning("请选择要续费的用户！")
                    return;
                }
                renewMember({
                    memberTypeNo:this.selectObj.memberTypeNo,
                    month:12,
                    renewalAccount:this.user.userlName,
                    userNos:dlist.join(','),
                    // funids:list.map(item => {return item.id}).join(',')
                    functionCodes: list.map(item => {return item.functionCode}).join(','),
                    userCoupons: this.selectCoupon.map(item => {return item.id}).join(',')
                    }).then(res => {
                    this.payObj = res.data
                    this.payVisible=true
                })
            }else{
                openMember({memberTypeNo:this.memberList[this.selected].memberTypeNo,userNo:this.user.userlName}).then(res => {
                    this.$message.success('领取成功!')
                    this.$router.go(0)
                    console.log(res)
                })
            }
        },
        async changeSelect(index){
            this.loading = true
            this.selected=index
            if(this.selectObj.curPrice&&this.selectObj.freeMonthNum>0){
                let {data} = await getServerByMemberType({memberTypeNo:this.selectObj.memberTypeNo})
                this.server = data
                let pay = await getPayFunByMemberType({memberTypeNo:this.selectObj.memberTypeNo})
                this.funcList = pay.data
                for(let p of this.funcList){
                    p.checked = false
                }
                let free = await getFreeFunByMemberType({memberTypeNo:this.selectObj.memberTypeNo})
                this.freeFunc = free.data
                for(let f of this.freeFunc){
                    f.checked = true
                }

            }else{
                let {data} = await getServerByMemberType({memberTypeNo:this.selectObj.memberTypeNo})
                this.server = data
            }
            this.loading = false
        },
        submitPay(value,payType){
            if(value){
                this.$message.success('开通成功!')
                this.$router.go(0)
            }
            // console.log(value)
            // console.log('支付')
        },
        couponSubmit(val){
            this.selectCoupon = val
        },
        openCouponSelect(){
            this.couponParam={
                memberCodes:this.memberList[this.selected].memberTypeNo,
                functionCodes:this.funcList.filter(f => f.checked).map(fitem => {return fitem.functionCode}).join(',')
            }
            this.couponSelectVisible=true
        }
    },
    watch: {
      dialogVisible(value) {
        // console.log('子组件下拉框值发生改变：', this.dialogVisible)
        this.$emit('update:visible', this.dialogVisible)
        if(value){
            this.selected = this.renewActive
            this.diaInit()
            console.log(this.user)
        }
      },
      visible(value){
        this.dialogVisible = value
      }
    },
    computed:{
        //当前选中的会员是否已开通且并未到期
        isKTmember(){
            let mem = this.user.openMember.find(item => item.memberTypeNo == this.memberList[this.selected].memberTypeNo)
            return mem
        },
        //当前选中的会员是否是超级会员开通
        isVvip(){
            // console.log(this.memberList[this.selected])
            if(this.memberList[this.selected]){
                let mem = this.user.openMember.find(item => item.memberTypeNo == this.memberList[this.selected].memberTypeNo)
                // console.log(mem)
                if(mem){
                    if(this.memberList[this.selected].vvipEndDate){
                        let now = new Date().getTime()
                        let vvipEndDate = new Date(this.memberList[this.selected].vvipEndDate).getTime()
                        // console.log(mem.isvvip==1?true:vvipEndDate > now && mem.isvvip!=-1)
                        return mem.isvvip==1?true:vvipEndDate > now && mem.isvvip!=-1
                    }else{
                        return false
                    }
                }
            }
            return false
        },
        //当前选中的会员是否已开通且并未到期
        isMember(){
            if(this.memberList[this.selected]){
                let mem = this.user.openMember.find(item => item.memberTypeNo == this.memberList[this.selected].memberTypeNo)
                if(mem){
                    let now = new Date().getTime()
                    let expiryDate = new Date(mem.expiryDate).getTime()
                    return expiryDate > now
                }
            }
            return false
        },
        //当前选中开通的会员
        openMember(){
            if(this.memberList[this.selected]){
                let mem = this.user.openMember.find(item => item.memberTypeNo == this.memberList[this.selected].memberTypeNo)
                return mem
            }
            return null
        },
        //当前选中开通的会员
        openFindMember(){
            return (index)=>{
                if(this.memberList[index]){
                    let mem = this.user.openMember.find(item => item.memberTypeNo == this.memberList[index].memberTypeNo)
                    return mem
                }
                return null
            }
        },
        selectObj(){
            if(this.memberList[this.selected]){
                return this.memberList[this.selected]
            }
            return {}
        },
        // 会员权益 付费功能
        allPrice(){
            var price = 0
            if(this.selectObj.curPrice){
                price = this.selectObj.curPrice
                let list = this.funcList.filter(f => f.checked)
                for(let item of list){
                    if(item.curPrice){
                        price += item.curPrice
                    }
                }
                // console.log(price)
                // console.log(this.dataList.length)
                // console.log(parseFloat((price * this.dataList.length).toFixed(10)))
                return parseFloat((price * this.dataList.length).toFixed(10));
            }
            return price
        },
        allPriceMany(){
            var price = 0
            if(this.selectObj.price){
                price = this.selectObj.price
                let list = this.funcList.filter(f => f.checked)
                for(let item of list){
                    if(item.price){
                        price += item.price
                    }
                }
                return price * this.dataList.length
            }
            return price
        },
        allSPrice(){
            var price = 0
            let list = this.funcList.filter(f => f.checked)
            for(let item of list){
                if(item.price){
                    price += item.price
                }
            }
            console.log(price)
            price = price * this.dataList.length
            return (this.allPriceMany-price)-this.allPrice
        },
        allCouponMoeny(){
            let money = 0;
            for(let item of this.selectCoupon){
                money += item.couponsMoney
            }
            return money
        }
    }
}
</script>
<style lang="less" scoped>
.avatar{
    float: left;
}
.name{
    float: left;
    font-size: 16px;
    line-height: 46px;
    margin-left: 12px;
    margin-bottom: 0px;
}
.date{
    float: left;
    // display: inline-block;
    // width: auto;
    font-size: 12px;
    // line-height: 46px;
    margin-left: 70px;
}
.close{
    float: right;
    line-height: 46px;
    font-size: 14px;
    color: #999999;
    &:hover span{
        cursor: pointer;
        color: #1890ff;
    }
}
.content{
    display: inline-block;
    text-align: left;
}
.memberType{
    .item{
        width: 365px;
        height: 153px;
        float: left;
        border: 1px solid #dedfe0;
        border-radius: 4px;
        margin-right:19px;
        cursor: pointer;
        margin-bottom: 17px;
        position: relative;
        padding: 0px 42px;
        padding-top: 35px;
        text-align: center;
        &:hover{
          box-shadow: 0px 1px 23px 0.9px rgba(216,216,216,0.60); 
        }
        img{
            width: 64px;
            height: 64px;
            float: left;
        }
        .money{
            float: left;
            margin-left: 5px;
            .real{
                // color: #2D9FFF;
                font-weight: 700;
                font-size: 30px;
                line-height: 1em;
                margin-top: 3px;
                span{
                    font-size: 14px;
                }
                .pri{
                    text-decoration:line-through;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                }
            }
            .free{
                font-weight: 700;
            }
        }
        .check{
            position: absolute;
            bottom: 3px;
            right: 3px;
            color: #ffffff;
        }
    }
    .active{
        border: none;
        background-image: url('../../assets/Image/popover/selected.png');
        background-size: 100% 100%;
        .real{
            color: #2D9FFF;
        }
        .free{
            color: #2D9FFF;
        }
        &:hover{
          box-shadow: none;
        }
    }
}
.server{
    width: 100%;
    height: 89px;
    background: #f4f5f6;
    padding: 20px;
    font-size: 16px;
    &-item{
        float: left;
        font-size: 14px;
        line-height: 22px;
        color: #333333;
        margin-right: 43px;
        span{
            display: block;
            float: left;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin-top: 9px;
            margin-right: 9px;
            background: #333333;
        }
    }
}
.renew-item{
    margin-top: 10px;
    padding-bottom: 10px;
    .header{
        font-size: 16px;
        color: #333333;
        margin-bottom: 13px;
    }
    .pri{
        text-decoration:line-through;
        font-size: 12px;
        // font-weight: 400;
        color: #999999;
        margin-right: 36px;
    }
    .btn{
        width: 42px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        display: inline-block;
        background: #faddb9;
        border: 1px solid #ca9c4a;
        border-radius: 2px;
        margin-right: 36px;
        margin-left: 5px;
        font-size: 10px;
        cursor: pointer;
        color: #725327;
        &:hover{
            color: #443117;
            border: 1px solid #725327;
        }
    }
    .add{
        margin-top: 3px;
        padding-left: 28px;
        font-size: 16px;
        color: #333333;
        line-height: 30px;
    }
}
.moneyBack{
    height: 158px;
    background: #f4f5f6;
    padding-left: 61px;
    padding-top: 21px;
    margin: 0px -24px;
    // margin: 20px -24px;
    .lrAll{
        margin-bottom: 6px;
        line-height: 25px;
    }
    .left{
        font-size: 12px;
        color: #999999;
        width: 30%;
        float: left;
    }
    .right{
        width: 70%;
        float: left;
        font-size: 16px;
        font-weight: 700;
        color: #333333;
        .pri{
            text-decoration:line-through;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            margin-left: 6px;
        }
        span{
            font-size: 12px;
            font-weight: 400;
            color: #999999;
        }
        .jfbtn{
            cursor: pointer;
            &:hover{
                color: rgb(39, 69, 158) !important;
            }
        }
    }
}
.allMoney{
    line-height: 46px;
    color: #333333;
    margin-top: 35px;
    .checkLeft{
        float: left;
        span{
            color: #3C69EB;
            cursor: pointer;
            &:hover{
                color: #28459b;
            }
        }
    }
    .moenyRight{
        float: right;
        .fmoney{
            font-size: 14px;
            color: #ff1e1e;
        }
        .nowMoney{
            font-size: 30px;
            font-weight: 700;
            color: #ff1e1e;
        }
        .realMoney{
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            margin-left: 5px;
            text-decoration:line-through;
        }
        .yhbtn{
            margin-left:15px;
            margin-right:22px;
            cursor: pointer;
            &:hover{
                color: #3C69EB;
            }
        }
        .pribtn{
            font-size: 18px;
            width: 114px;
            height: 46px;
            background: #4977fc;
            border-radius: 4px;
            &:hover{
                background: rgba(73, 118, 252, 0.9);
            }
        }
    }
}
/deep/ .ant-checkbox-wrapper{
    margin-left: 0px !important;
    margin-bottom: 8px !important;
}
.member-name{
    font-weight: 700;
}
.couponMoney{
    line-height: 38px;
    float: left;
    margin-right: 15px;
}
.couponInput{
    float: left;
    min-width: 200px;
    max-width: 750px;
    padding: 6px 15px;
    background: #ffffff;
    color: #999999;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    border: 1px solid #dedfe0;
    &:hover{
        border: 1px solid #2D9FFF;
    }
    .tag{
        border-radius: 14px;
        padding: 3px 15px;
        color: #ffffff !important;
        margin-bottom: 6px;
    }
}
.clear{
    clear: both;
}
</style>