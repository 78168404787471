import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import {SvgIcon} from './assets/icons' // icon
import * as Icons from "@ant-design/icons-vue";
import ellipsis from './components/ellipsis/index.vue' // 文本超出显示...

//字典封装
import { getDicts } from "@/api/dict/data";
// 字典标签组件
import DictTag from '@/components/DictTag'
// 字典数据组件
import DictData from '@/components/DictData'

const icons = Icons;


window.addEventListener('error', ()=>{})

const app = createApp(App)
app.config.globalProperties.$app = app

app.config.globalProperties.getDicts = getDicts
app.component('DictTag', DictTag)
DictData.install(app)
app.component('ellipsis',ellipsis).component('svg-icon',SvgIcon).use(Antd).use(store).use(router).mount('#app')

// 全局注册图标
for (const i in icons) {
    app.component(i, icons[i]);
}