<template>
    <a-modal title="充值" width="560px" v-model:visible="dialogVisible" centered :footer="null">
        <div class="money">
            余额：
            <span class="num" v-if="userOrEnter">{{user.account.accountMoney.toLocaleString('zh-CN', {style: 'currency', currency: 'CNY',})}}</span>
            <span class="num" v-else-if="account.accountMoney&&!userOrEnter">{{account.accountMoney.toLocaleString('zh-CN', {style: 'currency', currency: 'CNY',})}}</span>
        </div>
        <div class="header">充值金额</div>
        <div class="list">
            <div :class="num===activeMoney?'item acticeItem':'item'" v-for="(num,index) in option" :key="index" :style="(index+1)%5==0?'margin-right:0':''" @click="activeMoney=num">{{num}}元</div>
            <div :class="option.findIndex(num => num === activeMoney)==-1?'item acticeItem':'item'" style="margin-right:0" @click="openInput">
                <a-input type="number" v-model:value="moenyInput" :bordered="false" style="text-align: center;" @input="moneyChange" />
            </div>
            <div class="clear"></div>
        </div>
        <div class="btns">
            <a-button :loading="btnLoading" type="primary" @click="submitSelect">立即支付</a-button>
        </div>
        <pay v-model:visible="payVisible" :isInput="true" :money="activeMoney" :userOrEnter="userOrEnter"></pay>
    </a-modal>
</template>
<script>
import { list } from '@/mixins/list'
import { dialog } from '@/mixins/dialog'
import { getAccountEnter } from '@/api/account'
import pay from '@/components/pay'
export default {
    mixins:[list,dialog],
    props:{
        //企业或个人
        userOrEnter:{
            type:Boolean,
            default:true //true个人
        },
    },
    components: {
        pay
    },
    data(){
        return{
            account:{},
            payVisible:false,
            activeMoney:100,
            option:[100,200,300,500,1000,2000,3000,5000,10000],
            moenyInput:''
        }
    },
    methods:{
        diaInit(){
            this.clearForm()
            getAccountEnter().then(async res=>{
                this.account = res.data
                // console.log(this.account)
            })
        },
        submitSelect(){
            // console.log(this.activeMoney)
            if(this.activeMoney==''){
                this.$message.warning('请输入要充值的金额')
                return;
            }
            this.payVisible=true
        },
        openInput(){
            // console.log(typeof(this.activeMoney))
            if(typeof(this.activeMoney)!=="string"){
                this.activeMoney=''
            }
        },
        clearForm(){
            this.moenyInput = ""
            this.activeMoney = 100
        },
        moneyChange(e){
            // console.log(this.moenyInput)
            this.activeMoney = this.moenyInput
        }
    }
}
</script>
<style lang="less" scoped>
.btns{
    margin-top: 38px;
    text-align: center;
}
</style>
<style lang="less" scoped>
@import '@/assets/css/common.less';
.money{
    color: #333333;
    text-align: center;
    span{
        color: #FF1E1E;
        font-weight: bold;
    }
    .num{
        font-size: 30px;
    }
}
.header{
    margin-top: 15px;
    font-weight: 700;
}
.list{
    margin-top: 15px;
    .item{
        cursor: pointer;
        width: 90px;
        font-weight: 700;
        height: 40px;
        line-height: 40px;
        background: #ffffff;
        border: 1px solid #dedfe0;
        border-radius: 2px;
        margin-bottom: 15px;
        text-align: center;
        float: left;
        margin-right: 15px;
        &:hover{
            border: 1px solid #4977fc;
            background-color: #f0f7ff;
            color: #4977fc;
        }
    }
    .acticeItem{
        border: 1px solid #4977fc;
        color: #4977fc;
        background-color: #ffffff !important;
    }
}
.clear{
    clear: both;
}
</style>