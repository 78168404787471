import request from '@/utils/request'

const api = '/webapi/account/'
const tranApi ='/webapi/accountTransaction/'

// 获取当前企业账户
export function getAccountEnter(data) {
    return request({
      url: api+'getCurEnterprise',
      method: 'get',
      params:data
    })
}

// 获取账户列表
export function getAccountList(data) {
    return request({
      url: api+'list',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}

// 修改账户信息
export function updateAccount(data) {
    return request({
      url: api+'update',
      method: 'put',
      data:data
    })
}

// 获取当前用户账户
export function getAccountUser(data) {
    return request({
      url: api+'getCurUser',
      method: 'get',
      params:data
    })
}

// 充值
export function rechangeAccount(data) {
    return request({
      url: api+'rechange',
      method: 'post',
      data:data
    })
}

// 转账
export function transferAccount(data) {
    return request({
      url: api+'transfer',
      method: 'put',
      params:data
    })
}

// 交易流水列表
export function getTranList(data) {
    return request({
      url: tranApi+'list',
      method: 'post',
      headers: {
        'repeatSubmit': false
      },
      data:data
    })
}

// 修改交易流水
export function editTran(data) {
  return request({
    url: tranApi+'update',
    method: 'put',
    data:data
  })
}

// 修改交易状态
export function editTranStatus(data) {
    return request({
      url: tranApi+'editStatus',
      method: 'put',
      headers: {
        'repeatSubmit': false
      },
      params:data
    })
}

// 月度账单
export function getCurMonth(data) {
  return request({
    url: tranApi+'getCurMonth',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}

// 获取付款账户
export function getPayAccount(data){
  return request({
    url: api+'getPayAccount',
    method: 'get',
    params:data
  })

}

// 获取付款账户
export function getPayAccountByPhone(data){
  return request({
    url: api+'getPayAccountByPhone',
    method: 'get',
    params:data
  })

}

// 获取平台账户
export function getPlatAccount(data){
  return request({
    url: api+'getPlatAccount',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    params:data
  })
}
