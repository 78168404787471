<template>
<div ref="models">
    <a-modal :getContainer="getRefsModels"
        :title="null" :bodyStyle="{background:`url(${require('@/assets/Image/newUser/backgroundImg.png')})`,backgroundSize: 'cover',height: '480px'}" width="725px" v-model:visible="dialogVisible" centered :footer="null">
        <template #closeIcon>
            <CloseOutlined style="color:#ffffff" />
        </template>
        <img class="userText" :src="require('@/assets/Image/newUser/newUserText.png')" alt="">
        <div class="hyNum">
            仅限前
            <span class="num">{{allNum}}</span>
            名会员，仅剩
            <span class="num">{{allNum-curNum}}</span>
            个名额
        </div>
        <div class="userBtn" @click="openRenew">
            立即开通
        </div>
    </a-modal>
    <renew v-model:visible="visible" :renewActive="''"></renew>
</div>
</template>
<script>
import { list } from '@/mixins/list'
import renew from '@/components/renew'
import { dialog } from '@/mixins/dialog'
// import { upload } from '@/api/file'
export default {
    props:{
        allNum:{
            type:Number,
            default:0
        },
        curNum:{
            type:Number,
            default:0
        }
    },
    mixins:[list,dialog],
    components: {
        renew
    },
    data(){
        return{
            visible:false
        }
    },
    mounted(){
        this.$nextTick(()=>{
            console.log(this.$refs.models)
        })
    },
    methods:{
        getRefsModels() {
            return this.$refs.models
        },
        // 打开续费弹窗
        openRenew(){
            this.dialogVisible=false
            this.visible=true
        },
    }
}
</script>
<style lang="less" scoped>
/deep/ .ant-modal-content {
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
}
.userText{
    display: block;
    width: 547px;
    height: 209px;
    margin: 0 auto;
    margin-top: 40px;
    // margin-bottom: 15px;
}
.hyNum{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    font-style: italic;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 5px;
    .num{
        font-size: 36px;
        color: #FFE398;
    }
}
.userBtn{
    background: url('@/assets/Image/newUser/newUserBtn.png');
    background-size: cover;
    text-align: center;
    cursor: pointer;
    width: 140px;
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    font-weight: 700;
    color: #892500;
    margin: 0 auto;
    border-radius: 20px;
    &:hover{
        box-shadow: 0px 1px 23px 0.9px rgba(216,216,216,0.60); 
    }
}
.clear{
    clear: both;
}
</style>