<template>
<div>
    <a-popconfirm :placement="placement" icon=" " v-model:visible="popVisible" :showCancel="false">
        <template #okButton></template>
        <template #title>
            <div class="userInfo">
                <div class="menu">
                    <div class="menu-item" style="background: #eff4ff;" @click="openBus">
                        <img :src="user.member.enterprise.logo" alt="">
                        <ellipsis width="195" num="1" :content="user.member.enterprise.enterpriseName" textStyle="float:left"></ellipsis>
                        <swap-outlined style="float:right;font-size:14px;margin-top:15px;margin-right:12px" />
                        <div class="clear"></div>
                    </div>
                    <div class="menu-item" @click="tolink('/enterprise/info')" v-if="isOwnerOrManager">
                        <img :src="require('@/assets/Image/popover/enterprise.png')" alt=""> 企业中心
                    </div>
                    <div class="menu-item" @click="tolink('/organize')" v-if="isOwnerOrManager||user.org.length>0">
                        <img :src="require('@/assets/Image/popover/organization.png')" alt=""> 组织架构
                    </div>
                    <div class="menu-item" @click="openAddBus">
                        <img :src="require('@/assets/Image/popover/add.png')" alt=""> 添加企业
                    </div>
                </div>
            </div>
        </template>
        <slot name="avatar" :visible="popVisible"></slot>
    </a-popconfirm>
    <!-- <renew v-model:visible="visible" :renewActive="renewActive"></renew> -->
    <business v-model:visible="busVisible"></business>
    <addBusiness v-model:visible="addBusVisible"></addBusiness>
    <!-- <recharge v-model:visible="payVisible"></recharge>
    <coupon v-model:visible="couponVisible"></coupon>
    <downLoad v-model:visible="downLoadVisible"></downLoad>
    <inviteUser v-model:visible="inviteUserVisible"></inviteUser>
    <newCoupon v-model:visible="newCouponVisible"></newCoupon> -->
</div>
</template>
<script>
import business from './components/business'
import addBusiness from './components/addBusiness'
import {list} from '../../mixins/list'
import { mapGetters, mapActions } from "vuex";
import Cookies from 'js-cookie'
import { getCouponList, } from '@/api/coupon'
import { notification } from 'ant-design-vue';
export default {
    mixins:[list],
    components: {
        business,addBusiness
    },
    props:{
        placement:{
            type: String,
            default:'bottomRight'
        }
    },
    data(){
        return{
            visible: false,
            busVisible: false,
            popVisible:false,
            payVisible:false,
            addBusVisible:false,
            couponVisible:false,
            newCouponVisible:false,
            downLoadVisible:false,
            inviteUserVisible:false,
            renewActive:'',
        }
    },
    created(){
        this.isEStatus()
        // this.isTips()
        // this.isNewUser()
    },
    methods:{
        ...mapActions(['LogOut']),
        //当前登录企业是否已禁用
        isEStatus(){
            if(this.user.member.employees==null){
                this.$message.warning('账号架构数据异常，请联系管理员！')
                return;
            };
            console.log(this.user.member.employees)
            if(this.user.member.employees&&this.user.member.employees.status==-1){
                this.$message.warning('您已被当前企业禁用，请重新选择企业！')
                this.openBus()
                return;
            }
        },
        //新用户优惠卷领取弹窗
        isNewUser(){
            if(this.user.member.member.lastLoginTime==null){
                this.newCouponVisible = true
                return;
            }
        },
        //优惠卷每日提醒
        async isTips(){
            let tipsTime = Cookies.get('Tips-Time')
            if(!tipsTime||tipsTime!=new Date().toLocaleDateString()){
                let coupon = await getCouponList({})
                coupon = coupon.data
                if(coupon.filter(item => item.canUse==1&&item.expire==0).length>0){
                    Cookies.set('Tips-Time', new Date().toLocaleDateString())
                    notification.warning({
                        message: '系统通知',
                        description:
                        '您有一张优惠卷未使用，请尽快使用！',
                    });
                }
            }
        },
        // 打开续费弹窗
        openRenew(item){
            if(item){
                this.renewActive = item.memberTypeNo
            }else{
                this.renewActive = ''
            }
            this.popVisible=false
            this.visible=true
        },
        // 打开切换企业弹窗
        openBus(){
            this.popVisible=false
            this.busVisible=true
        },
        // 打开添加企业弹窗
        openAddBus(){
            this.popVisible=false
            this.addBusVisible=true
        },
        // 打开充值弹窗
        openPay(){
            this.popVisible=false
            this.payVisible=true
        },
        openDownLoad(){
            this.popVisible=false
            this.downLoadVisible=true
        },
        openInviteUser(){
            this.popVisible=false
            this.inviteUserVisible=true
        },
        // 退出
        loginOut(){
          this.LogOut().then(() => {
            this.$message.success('已退出')
            this.tolink('/login')
          })
        }
    },
    computed:{
        ...mapGetters(["user"]),
        //是否是当前企业的管理员
        isOwnerOrManager(){
            return this.user.userlName == this.user.member.enterprise.owner||this.user.userlName == this.user.member.enterprise.manager
        },
    }
}
</script>
<style lang="less" scoped>
.userInfo{
    margin-left: -42px;
    margin-top: -16px;
    margin-bottom: -36px;
    margin-right: -20px;
    min-width: 335px;
    min-height: 230px;
    // border: 1px solid #f2f3f5;
    border-radius: 10px;
    box-shadow: 0px 4px 10.56px 0.48px rgba(216,216,216,0.40); 
    background-color: #ffffff;
    &-top{
        background: url("../../assets/Image/userInfo.png") no-repeat;
        background-size:100% 100%;
        height: 90px;
        padding: 22px 0px;
        padding-left: 8px;
        padding-right: 15px;
        font-size: 12px;
        color: #ffffff;
        .userAvatar{
            float: left;
            cursor: pointer;
        }
        .user{
            float: left;
            margin-left: 8px;
            .name{
                font-size: 16px;
                line-height: 24px;
                float: left;
            }
            img{
                width: 24px;
                height: 24px;
                margin-left: 11px;
                float: left;
            }
            .money{
                margin-top: 5px;
            }
        }
        .date{
            float: right;
            margin-left: 28px;
        }
    }
}
.popover-list{
    // padding: 5px;
    .popover-item{
        width: 132px;
        line-height: 44px;
        border-radius: 4px;
        color: #333333;
        cursor: pointer !important;
        img{
            width: 20px;
            height: 20px;
            margin-top: 12px;
            margin-left: 15px;
            margin-right: 12px;
            float: left;
        }
        &:hover{
            background: #eff4ff;
        }
    }
}
.menu{
    padding: 20px;
    margin-bottom: 5px;
    .menu-item{
        line-height: 44px;
        border-radius: 4px;
        color: #333333;
        cursor: pointer !important;
        margin-bottom: 7px;
        img{
            width: 20px;
            height: 20px;
            margin-top: 12px;
            margin-left: 15px;
            margin-right: 12px;
            border-radius: 50%;
            float: left;
        }
        &:hover{
            background: #eff4ff;
        }
    }
}
.clear{
    clear: both;
}
</style>