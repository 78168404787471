export const dialog = {
  props:{
    visible:{
      type:Boolean,
      default:false
    }
  },
  model: {
    prop: 'visible'
  },
  data() {
    return {
      dialogVisible: this.visible,
    }
  },
  methods:{
    init(){

    },
    diaInit(){

    }
  },
  watch: {
    dialogVisible(value) {
        // console.log('子组件下拉框值发生改变：', this.dialogVisible)
        this.$emit('update:visible', this.dialogVisible)
        if(value){
          this.init()
          this.diaInit()
        }
    },
    visible(value){
        this.dialogVisible = value
    }
  }
}
