import request from '@/utils/request'

const api = '/pro/message/'
// 文件上传
export function getInfoList(data) {
  return request({
    url: api+'list',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    data: data
  })
}
// 添加消息
export function saveInfo(data) {
  return request({
    url: api+'save',
    method: 'post',
    headers: {
      'repeatSubmit': false
    },
    data: data
  })
}