<template>
    <a-modal :width="step==3?'800px':'600px'" :closable="step!=1"
        :title="step==1?'':title"
        :bodyStyle="step==1?{background:'linear-gradient(180deg,rgba(73,119,252,0.08) 0%, rgba(73,119,252,0.08) 38%, rgba(255,255,255,1) 100%)'}:step==3?{height:'800px',overflow: 'auto'}:{}"
        v-model:visible="dialogVisible" centered :footer="null">
        <div v-if="step==1">
            <div class="header">
                添加企业
                <close-outlined class="close" @click="dialogVisible=false" />
            </div>
            <div class="step-one">
                <!-- <a-input v-model:value="enterpriseName"  placeholder="请输入企业名称" size="large"/> -->
                <a-select v-model:value="enterpriseName" show-search placeholder="请输入企业名称" size="large"
                    style="text-align: left;display: block;width:100%" :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                    :not-found-content="null" :options="options" @search="handleSearch" @change="handleChange">
                </a-select>
                <a-button type="primary" class="btn" @click="searchOfName">确定</a-button>
            </div>
        </div>
        <div v-if="step==2" class="step-two">
            <div class="item">
                <div class="label">企业名称：</div>
                <div class="content">{{enterprise.enterpriseName}}</div>
                <div class="clear"></div>
            </div>
            <div class="item">
                <div class="label">企业介绍：</div>
                <div class="content">{{enterprise.enterpriseIntroduction?enterprise.enterpriseIntroduction:'暂无介绍'}}</div>
                <div class="clear"></div>
            </div>
            <div class="btnAll">
                <a-button type="danger" style="margin-right: 15px" @click="next(1)">取消</a-button>
                <a-button type="primary" @click="apply">申请加入</a-button>
            </div>
        </div>
        <div v-if="step==3" class="step-three">
            <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                <a-form-item label="企业名称：" style="line-height:39px">
                    {{form.enterpriseName}}
                    <a-button class="btn" type="primary">
                        <img style="" :src="require('@/assets/Image/project/tyc.png')" alt="">
                    </a-button>
                </a-form-item>
                <!-- <a-form-item label="企业名称：" name="enterpriseName">
                    <a-input v-model:value="form.enterpriseName"  placeholder="请输入企业名称" size="large"/>
                </a-form-item> -->
                <a-form-item label="企业简称：" name="abbreviation">
                    <a-input v-model:value="form.abbreviation"  placeholder="请输入企业简称" size="large"/>
                </a-form-item>
                <a-form-item label="统一社会信用代码：" name="creditCode">
                    <a-input v-model:value="form.creditCode"  placeholder="请输入统一社会信用代码" size="large"/>
                </a-form-item>
                <a-form-item label="企业类型：" name="enterpriseType">
                    <a-select v-model:value="form.enterpriseType" style="width: 100%" size="large" placeholder="请选择企业类型">
                        <a-select-option
                            v-for="dict in dict.type.company_type"
                            :key="dict.value"
                            :value="dict.value">
                            {{dict.label}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="所属行业：">
                    <a-cascader
                        v-model:value="form.industry" size="large"
                        :field-names="{ label: 'name', value: 'name', children: 'children' }"
                        :options="industry"
                        placeholder="请选择所属行业"/>
                </a-form-item>
                <a-form-item label="企业法人：">
                    <a-input v-model:value="form.legalPerson"  placeholder="请输入企业法人" size="large"/>
                </a-form-item>
                <a-form-item label="注册资本：">
                    <a-input-number
                    v-model:value="form.registeredCapital"
                    :min="0" placeholder="请输入注册资本" style="width: 100%" size="large"
                    :formatter="value => `${value}万`"
                    :parser="value => value.replace('万', '')"/>
                </a-form-item>
                <a-form-item label="注册地址：">
                    <a-input v-model:value="form.registeredAddress"  placeholder="请输入注册地址" size="large"/>
                </a-form-item>
                <a-form-item label="注册日期：">
                     <a-date-picker v-model:value="form.registeredDate" valueFormat="YYYY-MM-DD" placeholder="请选择注册日期" style="width: 100%" size="large"/>
                </a-form-item>
                <a-form-item label="核准日期：">
                     <a-date-picker v-model:value="form.approvalDate" valueFormat="YYYY-MM-DD" placeholder="请选择核准日期" style="width: 100%" size="large"/>
                </a-form-item>
                <a-form-item label="营业期限始：">
                     <a-date-picker v-model:value="form.businessTermStr" valueFormat="YYYY-MM-DD" placeholder="请选择营业期限始" style="width: 100%" size="large"/>
                </a-form-item>
                <a-form-item label="营业期限止：">
                     <a-date-picker v-model:value="form.businessTermEnd" valueFormat="YYYY-MM-DD" placeholder="请选择营业期限止" style="width: 100%" size="large"/>
                </a-form-item>
                <a-form-item label="登记机关：">
                    <a-input v-model:value="form.registrationAuthority"  placeholder="请输入登记机关" size="large"/>
                </a-form-item>
                <a-form-item label="电话：">
                    <a-input v-model:value="form.tel"  placeholder="请输入电话" size="large"/>
                </a-form-item>
                <a-form-item label="邮箱：">
                    <a-input v-model:value="form.email"  placeholder="请输入邮箱" size="large"/>
                </a-form-item>
                <a-form-item label="地址：">
                    <a-cascader
                        v-model:value="form.allAddress" size="large"
                        :field-names="{ label: 'name', value: 'name', children: 'children' }"
                        :options="country"
                        placeholder="请选择地址"/>
                </a-form-item>
                <a-form-item label="经营范围：">
                    <a-textarea v-model:value="form.businessScope" placeholder="请输入经营范围" size="large" :rows="4" />
                </a-form-item>
                <a-form-item label="企业介绍：">
                    <a-textarea v-model:value="form.enterpriseIntroduction" placeholder="请输入企业介绍" size="large" :rows="4" />
                </a-form-item>
                <div style="text-align: right;">
                    <a-button style="background: #4977fc;" :loading="btnLoading" type="primary" @click="submitAdd">提交</a-button>
                </div>
            </a-form>
        </div>
    </a-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {list} from '@/mixins/list'
import {enterSearchOfName,addEnterprise,applyOnEnterprise} from '@/api/enterprise'
import {country} from '@/assets/js/country'
import {industry} from '@/assets/js/industry'
export default {
    mixins:[list],
    dicts:['company_type'],
    props:{
        visible:{
            type:Boolean,
            default:false
        }
    },
    model: {
        prop: 'visible'
    },
    data() {
        return {
            dialogVisible: this.visible,
            title: '',
            enterpriseName:'',
            enterprise:{},
            btnLoading:false,
            step:1,
            country:country,
            industry:industry,
            form:{
                enterpriseName:'',
                abbreviation:'',
                creditCode:'',
                enterpriseType:null,
                industry:[],
                legalPerson:'',
                registeredCapital:'',
                registeredAddress:'',
                registeredDate:'',
                approvalDate:'',
                businessTermStr:'',
                businessTermEnd:'',
                registrationAuthority:'',
                tel:'',
                email:'',
                allAddress:[],
                province:'',
                city:'',
                district:'',
                businessScope:'',
                enterpriseIntroduction:'',
            },
            rules:{
                enterpriseName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                abbreviation: [{ required: true, message: '请输入企业简称', trigger: 'blur' }],
                creditCode: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
                enterpriseType: [{ required: true, message: '请输入企业类型', trigger: 'blur' }],
            },
            options:[]
        }
    },
    methods:{
        // 下一步
        next(index){
            this.step = index
            if(index == 1){
                this.enterpriseName=''
                return;
            }
            if(index == 2){
                this.title='加入企业'
            }else{
                this.clearFrom()
                this.title='新建企业'
            }
        },
        //查询系统内是否有此公司
        searchOfName(){
            if(this.enterpriseName==""){
                this.$message.warning('企业名称不能为空')
                return;
            }
            let obj = this.options.find(item => item.label==this.enterpriseName||item.value==this.enterpriseName)
            if(obj){
                this.enterpriseName = obj.value
                this.enterprise = obj.data
                this.next(2)
            }else{
                this.next(3)
                this.form.enterpriseName = this.enterpriseName
            }
            // this.loading = true
            // enterSearchOfName({enterpriseName:this.enterpriseName}).then(res => {
            //     this.loading = false
            //     if(res.rows[0]){
            //         this.enterprise = res.rows[0]
            //         this.next(2)
            //     }else{
            //         this.next(3)
            //         this.form.enterpriseName = this.enterpriseName
            //     }
            // })
        },
        //清除添加表单
        clearFrom(){
            this.form={
                enterpriseName:'',
                abbreviation:'',
                creditCode:'',
                enterpriseType:null,
                industry:[],
                legalPerson:'',
                registeredCapital:'',
                registeredAddress:'',
                registeredDate:'',
                approvalDate:'',
                businessTermStr:'',
                businessTermEnd:'',
                registrationAuthority:'',
                tel:'',
                email:'',
                allAddress:[],
                province:'',
                city:'',
                district:'',
                businessScope:'',
                enterpriseIntroduction:'',
            }
        },
        //提交添加表单
        submitAdd(){
            this.$refs.form.validate().then(() => {
                this.btnLoading=true
                let param = Object.assign({},this.form)
                param.industry = param.industry.join(",")
                if(param.allAddress.length>0){
                    param.province = param.allAddress[0]
                    param.city = param.allAddress[1]
                    param.district = param.allAddress[2]?param.allAddress[2]:''
                }
                addEnterprise(param).then(res => {
                    // this.enterprise = {
                    //     enterpriseName:param.enterpriseName,
                    //     enterpriseIntroduction:param.enterpriseIntroduction
                    // }
                    // this.next(2)
                    this.btnLoading=false
                    this.$message.success('创建成功！')
                    this.dialogVisible = false
                })
            })
        },
        apply(){
            applyOnEnterprise({eno:this.enterprise.eno,userNO:this.user.userlName,status:2}).then(res => {
                this.$message.success('等待审核人员同意后进入该企业！')
                this.dialogVisible = false
            })
        },
        handleSearch(val){
            if(val){
                this.enterpriseName = val
                enterSearchOfName({enterpriseName:val}).then(res => {
                    // console.log(res.rows)
                    this.options = res.rows.map(item => {
                        return{
                            label:item.enterpriseName,
                            value:item.eno,
                            data:item
                        }
                    })
                })
            }
        },
        handleChange(val){
            console.log(val)
        }
    },
    watch: {
        dialogVisible(value) {
        // console.log('子组件下拉框值发生改变：', this.dialogVisible)
            if(!this.dialogVisible){
                this.step=1
                this.enterpriseName=''
                this.btnLoading=false
                this.clearFrom()
            }
            this.$emit('update:visible', this.dialogVisible)
        },
        visible(value){
            this.dialogVisible = value
        }
    },
    computed:{
        ...mapGetters(["user"]),
    }
}
</script>
<style lang="less" scoped>
.header{
    font-size: 16px;
    color: #333333;
    margin-bottom: 55px;
    .close{
        float: right;
    }
}
.step-one{
    width: 400px;
    margin: 0 auto;
    text-align: center;
    .btn{
        margin-top: 35px;
        margin-bottom: 10px;
    }
}
.step-two{
    width: 400px;
    margin: 0 auto;
    .item{
        margin-bottom: 20px;
        .label{
            float: left;
            width: 70px;
        }
        .content{
            float: left;
            width: 330px;
        }
    }
    .btnAll{
        text-align: right;
        margin-top: 35px;
        margin-bottom: 10px;
    }
}
.step-three{
    width: 630px;
    margin: 0 auto;
    .btn{
        float:right;
        width: 106px;
        height: 39px;
        font-size: 18px;
        // line-height: 39px;
        padding: 0px;
        border-radius: 4px;
        background-color: #4977fc;
        img{
            vertical-align: inherit;
            margin-top: 6.5px;
            width: 80px;
            height: 22px;
        }
    }
}
.clear{
    clear: both;
}
</style>