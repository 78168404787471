//多维数组查找满足条件的指定对象
export function findManyArray(data,chilrenName,type,value) {
    for(let item of data){
        if(item[type]==value){
            return item;
        }
        if(item[chilrenName]!=undefined&&item[chilrenName].length){
            let obj = findManyArray(item[chilrenName],chilrenName,type,value)
            // console.log(obj)
            if(obj){
                return obj
            }
        }
    }
}
// 复制到粘贴板
export function fallbackCopyTextToClipboard() {
    // 1.创建一个可选中元素
    let textArea = document.createElement("textarea");
    textArea.value = window.location.href;
    // 2.使用定位，阻止页面滚动
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }
    // 3.移除元素
    document.body.removeChild(textArea);
}
/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}
//将数组中相同的元素进行分组
export function sortClass(sortData){
  const groupBy = (array, f) => {
    let groups = {};
    array.forEach((o) => {
      let group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  };
  const sorted = groupBy(sortData, (item) => {
    return item.functionClassCode; // 返回需要分组的对象
  });
  // console.log(sorted)
  return sorted;
};

// 深度克隆数组
export function clone(o) {
	let res = {}
	if(o instanceof Array) {
		res = o.map(i => clone(i))
	}else {
		let keys = Object.keys(o)
		keys.forEach(i => {
			if(typeof o[i] === 'object' && o[i]) {
				res[i] = clone(o[i])
			}else{
				res[i] = o[i]
			}
		})
	}
	return res
}
//手机号加密
export function encryPhone(val, head = 3, last = 4){
  if (!val) { // 字符串为空直接返回
    return val;
  }
  if (val.length <= 10) { // 少于十位的字符串只显示前三位
    last = 0;
  }
  let str = '*';
  let len = val.length - head - last;
  len = len<0?0:len
  str = str.repeat(len); // * 重复len次
  let re = new RegExp('(.{' + head + '}).*(.{' + last + '})', ''); // 动态的正则验证
  return val.replace(re, '$1'+str+'$2'); // 替换
};
export function GetQueryString(url,name) {
  //截取第一个？后面的字符串
  var indexs = url.indexOf("？")
  var urlContent = url.substring(indexs + 1, url.length);
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = urlContent.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";
  if (r != null)
  context = r[2];
  reg = null;
  r = null;
  return context == null || context == "" || context == "undefined" ? "" : context;
}
// 计算相差月
export function monDiff(startTime,endTime){
  console.log(startTime,endTime)
  startTime=new Date(startTime);
  endTime=new Date(endTime);
  var date2Mon;
  var startDate=startTime.getDate()+startTime.getHours()/24+startTime.getMinutes()/24/60;
  var endDate=endTime.getDate()+endTime.getHours()/24+endTime.getMinutes()/24/60;
  if(endDate>=startDate){
    date2Mon=0;
  }else{
    date2Mon=-1;
  }
  return (endTime.getYear()-startTime.getYear())*12+endTime.getMonth()-startTime.getMonth()+date2Mon;
}

export function arrayMax(arrs) {
  var max = arrs[0];
  for(var i = 1,ilen = arrs.length; i < ilen; i++) {
      if(arrs[i] > max) {
          max = arrs[i];
      }
  }
  return max;
}