//若没有key请避免name重复造成数据混乱
let industry = [
    {
        code:'A',
        name:'农、林、牧、渔业',
        children: [
            {
                code: "01",
                name: "农业"
            },
  {
                code: "02",
                name: "林业"
            },
 {
                code: "03",
                name: "畜牧业"
            },
 {
                code: "04",
                name: "渔业"
            },
 {
                code: "05",
                name: "农、林、牧、渔专业及辅助性活动"
            }
        ]
    },
    {
        code:'B',
        name:'采矿业',
        children: [
            {
                code: "06",
                name: "煤炭开采和洗选业"
            },
  {
                code: "07",
                name: "石油和天然气开采业"
            },
  {
                code: "08",
                name: "黑色金属矿采选业"
            },
  {
                code: "09",
                name: "有色金属矿采选业‥"
            },
  {
                code: "10",
                name: "非金属矿采选业"
            },
  {
                code: "11",
                name: "开采专业及辅助性活动"
            },
  {
                code: "12",
                name: "其他采矿业"
            }
        ]
    },
 {
        code:'C',
        name:'制造业',
        children: [
            {
                code: "13",
                name: "农副食品加工业"
            },
  {
                code: "14",
                name: "食品制造业‥"
            },
 {
                code: "15",
                name: "酒、饮料和精制茶制造业"
            },
 {
                code: "16",
                name: "烟草制品业"
            },
 {
                code: "17",
                name: "纺织业"
            },
 {
                code: "18",
                name: "纺织服装、服饰业"
            },
 {
                code: "19",
                name: "皮革、毛皮、羽毛及其制品和制鞋业"
            },
 {
                code: "20",
                name: "木材加工和木、竹、藤、棕、草制品业"
            },
 {
                code: "21",
                name: "家具制造业"
            },
 {
                code: "22",
                name: "造纸和纸制品业"
            },
 {
                code: "23",
                name: "印刷和记录媒介复制业"
            },
 {
                code: "24",
                name: "文教、工美、体育和娱乐用品制造业"
            },
 {
                code: "25",
                name: "石油、煤炭及其他燃料加工业"
            },
 {
                code: "26",
                name: "化学原料和化学制品制造业"
            },
 {
                code: "27",
                name: "医药制造业"
            },
 {
                code: "28",
                name: "化学纤维制造业"
            },
 {
                code: "29",
                name: "橡胶和塑料制品业"
            },
 {
                code: "30",
                name: "非金属矿物制品业"
            },
 {
                code: "31",
                name: "黑色金属冶炼和压延加工业"
            },
 {
                code: "32",
                name: "有色金属冶炼和压延加工业"
            },
 {
                code: "33",
                name: "金属制品业"
            },
 {
                code: "34",
                name: "通用设备制造业"
            },
 {
                code: "35",
                name: "专用设备制造业"
            },
 {
                code: "36",
                name: "汽车制造业"
            },
 {
                code: "37",
                name: "铁路、船舶、航空航天和其他运输设备制造业"
            },
 {
                code: "38",
                name: "电气机械和器材制造业"
            },
 {
                code: "39",
                name: "计算机、通信和其他电子设备制造业"
            },
 {
                code: "40",
                name: "仪器仪表制造业"
            },
 {
                code: "41",
                name: "其他制造业"
            },
 {
                code: "42",
                name: "废弃资源综合利用业"
            },
 {
                code: "43",
                name: "金属制品、机械和设备修理业"
            }
        ]
    },
 {
        code:'D',
        name:'电力、热力、燃气及水生产和供应业',
        children: [
            {
                code: "44",
                name: "电力、热力生产和供应业"
            },
  {
                code: "45",
                name: "燃气生产和供应业"
            },
 {
                code: "46",
                name: "水的生产和供应业"
            }
        ]
    },
 {
        code:'E',
        name:'建筑业',
        children: [
            {
                code: "47",
                name: "房屋建筑业"
            },
  {
                code: "48",
                name: "土木工程建筑业"
            },
 {
                code: "49",
                name: "建筑安装业"
            },
{
                code: "50",
                name: "建筑装饰、装修和其他建筑业"
            }
        ]
    },
{
        code:'F',
        name:'批发和零售业',
        children: [
            {
                code: "51",
                name: "批发业"
            },
  {
                code: "52",
                name: "零售业"
            }
        ]
    },
{
        code:'G',
        name:'交通运输、仓储和邮政业',
        children: [
            {
                code: "53",
                name: "铁路运输业"
            },
  {
                code: "54",
                name: "道路运输业"
            },
 {
                code: "55",
                name: "水上运输业"
            },
 {
                code: "56",
                name: "航空运输业"
            },
 {
                code: "57",
                name: "管道运输业"
            },
 {
                code: "58",
                name: "多式联运和运输代理业"
            },
 {
                code: "59",
                name: "装卸搬运和仓储业"
            },
 {
                code: "60",
                name: "邮政业"
            }
        ]
    },
{
        code:'H',
        name:'住宿和餐饮业',
        children: [
            {
                code: "61",
                name: "住宿业"
            },
  {
                code: "62",
                name: "餐饮业"
            }
        ]
    },
{
        code:'I',
        name:'信息传输、软件和信息技术服务业',
        children: [
            {
                code: "63",
                name: "电信、广播电视和卫星传输服务"
            },
  {
                code: "64",
                name: "互联网和相关服务"
            },
 {
                code: "65",
                name: "软件和信息技术服务业"
            }
        ]
    },
{
        code:'J',
        name:'金融业',
        children: [
            {
                code: "66",
                name: "货币金融服务"
            },
  {
                code: "67",
                name: "资本市场服务"
            },
 {
                code: "68",
                name: "保险业"
            },
 {
                code: "69",
                name: "其他金融业"
            }
        ]
    },
{
        code:'K',
        name:'房地产业',
        children: [
            {
                code: "70",
                name: "房地产业"
            }
        ]
    },
{
        code:'L',
        name:'租赁和商务服务业',
        children: [
            {
                code: "71",
                name: "租赁业"
            },
  {
                code: "72",
                name: "商务服务业"
            }
        ]
    },
{
        code:'M',
        name:'科学研究和技术服务业',
        children: [
            {
                code: "73",
                name: "研究和试验发展"
            },
  {
                code: "74",
                name: "专业技术服务业"
            },
 {
                code: "75",
                name: "科技推广和应用服务业"
            }
        ]
    },
{
        code:'N',
        name:'水利、环境和公共设施管理业',
        children: [
            {
                code: "76",
                name: "水利管理业"
            },
  {
                code: "77",
                name: "生态保护和环境治理业"
            },
 {
                code: "78",
                name: "公共设施管理业"
            },
{
                code: "79",
                name: "土地管理业"
            }
        ]
    },
{
        code:'O',
        name:'居民服务、修理和其他服务业',
        children: [
            {
                code: "80",
                name: "居民服务业"
            },
  {
                code: "81",
                name: "机动车、电子产品和日用产品修理业"
            },
 {
                code: "82",
                name: "其他服务业"
            }
        ]
    },
{
        code:'P',
        name:'教育',
        children: [
            {
                code: "83",
                name: "教育"
            }
        ]
    },
{
        code:'Q',
        name:'卫生和社会工作',
        children: [
            {
                code: "84",
                name: "卫生"
            },
  {
                code: "85",
                name: "社会工作"
            }
        ]
    },
{
        code:'R',
        name:'文化、体育和娱乐业',
        children: [
            {
                code: "86",
                name: "新闻和出版业"
            },
  {
                code: "87",
                name: "广播、电视、电影和录音制作业"
            },
 {
                code: "88",
                name: "文化艺术业"
            },
 {
                code: "89",
                name: "体育"
            },
 {
                code: "90",
                name: "娱乐业"
            }
        ]
    },
{
        code:'S',
        name:'公共管理、社会保障和社会组织‥',
        children: [
            {
                code: "91",
                name: "中国共产党机关"
            },
  {
                code: "92",
                name: "国家机构"
            },
 {
                code: "93",
                name: "人民政协、民主党派"
            },
 {
                code: "94",
                name: "社会保障"
            },
 {
                code: "95",
                name: "群众团体、社会团体和其他成员组织"
            },
{
                code: "96",
                name: "基层群众自治组织"
            }
        ]
    },
{
        code:'T',
        name:'国际组织',
        children: [
            {
                code: "97",
                name: "国际组织"
            }
        ]
    }
]
export { industry }